import { WppButton, WppIconPlus } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { showImportEntitiesModal } from 'components/importEntitiesModal/ImportEntitiesModal'
import { ExportButton, FetchExportFn } from 'pages/components/tablePageActionButtons/components/ExportButton'
import { LocationState } from 'types/masterData/state'
import { FilterState } from 'types/masterData/utils'
import { routesManager } from 'utils/routesManager'

interface Props {
  state: FilterState
  fetchExportFn: FetchExportFn
  hasResults: boolean
  upsertState?: any
}

export const TablePageActionButtons = ({ state, fetchExportFn, hasResults, upsertState }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const moduleName = pathname.substring(1) as keyof (typeof routesManager)['masterData']

  return (
    <>
      <ExportButton disabled={!hasResults} fetchExportFn={fetchExportFn} state={state} />
      <WppButton
        variant="secondary"
        onClick={() =>
          navigate(routesManager.masterData[moduleName].create.getURL(), {
            state: {
              filters: upsertState,
            } satisfies LocationState,
          })
        }
      >
        <WppIconPlus slot="icon-start" />
        {t('common.create')}
      </WppButton>
      <WppButton onClick={() => showImportEntitiesModal({ moduleName })}>{t('import.import_button')}</WppButton>
    </>
  )
}
