import { createContext, useContext } from 'react'
import { useSetState } from 'react-use'

import {
  EntityProviderState,
  SelectVersionProps,
} from 'pages/components/upsertEntityWrapper/components/UpsertEntityProvider'

export interface StateType {
  contextState: EntityProviderState
  setContextState: ReturnType<typeof useSetState<EntityProviderState>>[1]
  handleSelectVersion: (arg: SelectVersionProps) => void
}

export const UpsertEntityContext = createContext<StateType>(null!)

export const useUpsertEntityContext = () => useContext(UpsertEntityContext)
