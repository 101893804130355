import { LabelConfig } from '@platform-ui-kit/components-library'
import {
  WppLabel,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTag,
} from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import { useField } from 'hooks/form/useField'

export interface SegmentOption {
  value: string
  label: string
}

interface Props
  extends Omit<ComponentPropsWithoutRef<typeof WppSegmentedControl>, 'onChange' | 'onWppChange' | 'value'> {
  labelConfig?: LabelConfig
  name: string
  required?: boolean
  disabled?: boolean
  tagMessage?: string
  options: SegmentOption[]
  onControlItemClicked?: (value: string) => void
}

export const FormSegmentedControl = forwardRef<HTMLWppSegmentedControlElement, Props>(
  ({ name, options, labelConfig, disabled, tagMessage, onControlItemClicked, required = false, ...rest }, ref) => {
    const innerRef = useRef<HTMLWppSegmentedControlElement>(null)
    const { t } = useTranslation()
    const {
      field: { value, onChange, onBlur },
    } = useField({ name })

    return (
      <Flex direction="column" gap={8}>
        {name && labelConfig && (
          <WppLabel
            config={{
              ...labelConfig,
              locales: {
                optional: t('common.label.optional'),
              },
            }}
            htmlFor={name}
            typography="s-strong"
            optional={!required}
          />
        )}
        <Flex gap={10} align="center">
          <WppSegmentedControl {...rest} onWppBlur={onBlur} ref={mergeRefs([innerRef, ref])} value={value}>
            {options.map(({ value, label }) => (
              <WppSegmentedControlItem
                onWppChangeSegmentedControlItem={() => {
                  onChange(value)
                  onControlItemClicked?.(value)
                }}
                value={value}
                key={value}
                disabled={disabled}
              >
                {label}
              </WppSegmentedControlItem>
            ))}
          </WppSegmentedControl>
          {tagMessage && <WppTag label={tagMessage} categoricalColorIndex={1} />}
        </Flex>
      </Flex>
    )
  },
)
