import { ConvertClientFormProps } from 'pages/clients/merge/form/types'
import { convertFormToFormDto } from 'pages/clients/merge/utils'
import { uniqBy } from 'utils/common'

export const convertFormToPreview = ({ values, data }: ConvertClientFormProps) => {
  const formDTO = convertFormToFormDto({ values, data })

  const entityLogo = data.find(entity => entity.logoThumbnail?.key === values.logoThumbnail)

  const children = uniqBy(
    data.flatMap(entity => entity.children),
    entity => entity.id,
  )

  const brands = uniqBy(
    data.flatMap(entity => entity.brands),
    entity => entity.id,
  )

  const parent = data.find(entity => entity?.parent?.id === formDTO.parentId)?.parent

  return {
    ...formDTO,
    logoThumbnail: entityLogo?.logoThumbnail!,
    logoOriginal: entityLogo?.logoOriginal!,
    children,
    brands,
    parent,
  }
}
