import { WppSkeleton } from '@platform-ui-kit/components-library-react'

import styles from 'components/cellRenderers/skeletons/avatarWithInfoSkeleton/AvatarWithInfoSkeleton.module.scss'
import { Flex } from 'components/common/flex/Flex'

export const AvatarWithInfoSkeleton = () => (
  <Flex className={styles.root} gap={16} align="center">
    <WppSkeleton width={32} height={32} variant="circle" />
    <WppSkeleton className={styles.info} height="50%" />
  </Flex>
)
