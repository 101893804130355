import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'
import { MayBeNull } from '@wpp-open/core'

import { MergeableEntity } from 'types/masterData/mergeEntities'
import { EntityAllFields, EntityFormAllFields } from 'types/masterData/utils'

interface Props<T extends EntityAllFields> {
  entries: T[]
  extractFn: (arg: T) => MayBeNull<AutocompleteDefaultOption>
}

export const prepareParentId = <T extends EntityAllFields>({ entries: [entry1, entry2], extractFn }: Props<T>) => {
  const parentId1 = extractFn(entry1)
  const parentId2 = extractFn(entry2)
  const isSameValue = parentId1?.id === parentId2?.id

  if (!parentId1 && !parentId2) return []

  return [
    {
      id: 'parentId',
      index: 0,
      entry1: {
        data: parentId1,
        isSameValue,
        entryName: entry1?.name! || entry1?.displayName!,
      },
      entry2: {
        data: parentId2,
        isSameValue,
        entryName: entry2?.name! || entry2?.displayName!,
      },
    } as const satisfies MergeableEntity<EntityFormAllFields>,
  ]
}
