import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { ComponentProps } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { TableEmptyCell } from 'components/common/table'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'

interface Props extends ComponentProps<typeof Flex> {
  label: string
  text: MayBeNull<string>
  hasEmptyState?: boolean
}

export const EntityTextPreview = ({ label, text, hasEmptyState, ...rest }: Props) => {
  if (!text && !hasEmptyState) return null
  return (
    <Flex direction="column" gap={8} {...rest}>
      <WppTypography type="s-strong" className={styles.labelText}>
        {label}
      </WppTypography>
      {text ? <WppTypography type="s-body">{text}</WppTypography> : TableEmptyCell}
    </Flex>
  )
}
