import { Navigate, Route } from 'react-router-dom'

import { RoutePermittedAccess } from 'app/routes/RoutePermittedAccess'
import { NotFound } from 'pages/404/NotFound'
import { Agencies } from 'pages/agencies/Agencies'
import { Brands } from 'pages/brands/Brands'
import { Clients } from 'pages/clients/Clients'
import { TableNavWrapper } from 'pages/components/tableNavWrapper/TableNavWrapper'
import { Currencies } from 'pages/currencies/Currencies'
import { Geographies } from 'pages/geographies/Geographies'
import { Industries } from 'pages/industries/Industries'
import { Markets } from 'pages/markets/Markets'
import { AppPermissions } from 'types/permissions/permissions'
import { routesManager } from 'utils/routesManager'

export const AppRoutes = (
  <>
    <Route
      path={routesManager.home.root.pattern}
      element={
        <TableNavWrapper>
          <RoutePermittedAccess permission={AppPermissions.OS_MASTERDATA_ENTITIES_MODIFY} />
        </TableNavWrapper>
      }
    >
      <Route index element={<Navigate to={routesManager.masterData.agencies.root.getURL()} replace />} />

      <Route path={routesManager.masterData.agencies.root.pattern} element={<Agencies />} />
      <Route path={routesManager.masterData.clients.root.pattern} element={<Clients />} />
      <Route path={routesManager.masterData.currencies.root.pattern} element={<Currencies />} />
      <Route path={routesManager.masterData.brands.root.pattern} element={<Brands />} />
      <Route path={routesManager.masterData.industries.root.pattern} element={<Industries />} />
      <Route path={routesManager.masterData.markets.root.pattern} element={<Markets />} />
      <Route path={routesManager.masterData.geographies.root.pattern} element={<Geographies />} />

      <Route path="*" element={<NotFound />} />
    </Route>
    <Route path={routesManager['404'].root.pattern} element={<NotFound />} />
  </>
)
