import { LabelConfig, LabelLocales } from '@platform-ui-kit/components-library'
import { DropdownConfig } from '@platform-ui-kit/components-library/dist/types/types/common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useCommonLabelProps = ({
  labelConfig,
  labelTooltipConfig,
}: {
  labelConfig?: LabelConfig
  labelTooltipConfig?: DropdownConfig
}) => {
  const { t } = useTranslation()

  const labelLocales = useMemo<LabelLocales>(
    () => ({
      optional: t('common.label.optional'),
    }),
    [t],
  )

  let labelConfigResult = labelConfig
  let labelTooltipConfigResult = labelTooltipConfig

  if (labelConfigResult) {
    const { text, description, icon, locales = labelLocales } = labelConfigResult

    labelConfigResult = {
      text,
      description,
      locales,
      icon,
      ...(!icon && !!description && { icon: 'wpp-icon-info' }),
    }

    if (labelTooltipConfigResult) {
      const { placement, ...rest } = labelTooltipConfigResult

      labelTooltipConfigResult = {
        ...rest,
        ...(!!description && !placement && { placement: 'right' }),
      }
    } else {
      if (description) {
        labelTooltipConfigResult = { placement: 'right' }
      }
    }
  }

  return { labelConfig: labelConfigResult, labelTooltipConfig: labelTooltipConfigResult }
}
