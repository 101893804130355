import { useSetState, useShallowCompareEffect } from 'react-use'

import { Entity } from 'components/common/readonlyPills/ReadonlyPills'
import { useStableCallback } from 'hooks/useStableCallback'

interface Props<T> {
  allValues: T[]
  getItemLabel: (option: T) => string
  getItemLogo: (option: T) => string
}

const PILLS_PAGE_SIZE = 40
export const usePaginatePills = <T extends Entity>({ allValues = [], getItemLabel, getItemLogo }: Props<T>) => {
  const mapPills = useStableCallback((values: T[]) =>
    values
      .sort((a, b) => getItemLabel(a)?.localeCompare(getItemLabel(b)))
      .map(item => ({
        id: item.id,
        label: getItemLabel(item),
        avatarUrl: getItemLogo(item),
      })),
  )

  const [{ allPills, offset }, setState] = useSetState({
    allPills: mapPills(allValues),
    offset: PILLS_PAGE_SIZE,
  })

  useShallowCompareEffect(() => {
    setState({
      allPills: mapPills(allValues),
      offset: PILLS_PAGE_SIZE,
    })
  }, [allValues])

  return {
    hasNextPage: allPills.length > offset,
    shownPills: allPills.slice(0, offset),
    showMore: () =>
      setState(({ offset }) => ({
        offset: offset + PILLS_PAGE_SIZE,
      })),
  }
}
