// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hifXa{min-height:100%;padding-bottom:25px}.CMyOL{flex-grow:1}.fQV2M{width:35%}.VcbK0{width:65%}.G1lEM{display:flex;flex-grow:1}.G1lEM::part(card){flex-grow:1;min-height:100%}.JS9G7 ::part(icon-tick){margin-top:-6px}.aiqVN{width:64px;height:64px;border-radius:50%}.I2geN{margin-bottom:24px;color:var(--wpp-grey-color-800)}.GTVes{color:var(--wpp-grey-color-800)}.bcHQe{min-height:400px}.bcHQe .ag-overlay-no-rows-wrapper{height:400px}.EvHHy{width:400px}`, "",{"version":3,"sources":["webpack://./src/pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,mBAAA,CAGF,OACE,WAAA,CAGF,OACE,SAAA,CAGF,OACE,SAAA,CAGF,OACE,YAAA,CACA,WAAA,CAEA,mBACE,WAAA,CACA,eAAA,CAKF,yBACE,eAAA,CAIJ,OACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,OACE,kBAAA,CACA,+BAAA,CAGF,OACE,+BAAA,CAGF,OACE,gBAAA,CAEA,mCACE,YAAA,CAIJ,OACE,WAAA","sourcesContent":[".container {\n  min-height: 100%;\n  padding-bottom: 25px;\n}\n\n.blockWrapper {\n  flex-grow: 1;\n}\n\n.rightBlock {\n  width: 35%;\n}\n\n.leftBlock {\n  width: 65%;\n}\n\n.fullHeightCard {\n  display: flex;\n  flex-grow: 1;\n\n  &::part(card) {\n    flex-grow: 1;\n    min-height: 100%;\n  }\n}\n\n.tableWrapper {\n  ::part(icon-tick) {\n    margin-top: -6px; // fix checkbox rendered in the table\n  }\n}\n\n.logo {\n  width: 64px;\n  height: 64px;\n  border-radius: 50%;\n}\n\n.descriptionText {\n  margin-bottom: 24px;\n  color: var(--wpp-grey-color-800);\n}\n\n.labelText {\n  color: var(--wpp-grey-color-800);\n}\n\n.emptyTable {\n  min-height: 400px;\n\n  :global(.ag-overlay-no-rows-wrapper) {\n    height: 400px;\n  }\n}\n\n.nameField {\n  width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `hifXa`,
	"blockWrapper": `CMyOL`,
	"rightBlock": `fQV2M`,
	"leftBlock": `VcbK0`,
	"fullHeightCard": `G1lEM`,
	"tableWrapper": `JS9G7`,
	"logo": `aiqVN`,
	"descriptionText": `I2geN`,
	"labelText": `GTVes`,
	"emptyTable": `bcHQe`,
	"nameField": `EvHHy`
};
export default ___CSS_LOADER_EXPORT___;
