import { WppActionButton, WppLabel, WppPill, WppPillGroup } from '@platform-ui-kit/components-library-react'
import { AttachmentMetadataResponse, MayBeNull } from '@wpp-open/core'
import { ComponentProps, forwardRef, Ref, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { useCommonLabelProps } from 'components/common/utils'
import { AvatarWithLabelPill } from 'components/form/formAutocomplete/AvatarWithLabelPill'
import { usePaginatePills } from 'components/form/formReadonlyPills/usePaginatePills'
import { FormWrapTag } from 'components/form/formWrapTag/FormWrapTag'
import { routesManager } from 'utils/routesManager'

export interface Entity {
  id: string
  name: string
  logoThumbnail?: MayBeNull<AttachmentMetadataResponse>
  [rest: string]: any
}

export interface Props<T extends Entity> extends Omit<ComponentProps<typeof WppPillGroup>, 'name' | 'value'> {
  navigationTo?: keyof typeof routesManager.masterData
  value: T[]
  name?: never
  hideWhenEmpty?: boolean
  getItemLabel?: (option: T) => string
  getItemLogo?: (option: T) => string
  tagMessage?: string
  disabled?: boolean
  pillMaxLength?: number
  'data-testid'?: string
}

export const ReadonlyPills = forwardRef<HTMLWppPillGroupElement, Props<Entity>>(function FormReadonlyPills<
  T extends Entity,
>(
  {
    value,
    labelConfig,
    labelTooltipConfig,
    navigationTo,
    hideWhenEmpty = true,
    getItemLabel = item => item?.name!,
    getItemLogo = item => item?.logoThumbnail?.url!,
    tagMessage,
    disabled,
    pillMaxLength = 0,
    'data-testid': dataTestId,
    ...rest
  }: Props<T>,
  ref: Ref<HTMLWppPillGroupElement>,
) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

  const { shownPills, hasNextPage, showMore } = usePaginatePills<T>({ allValues: value, getItemLogo, getItemLabel })

  const handlePillClick = useCallback(
    (entryId: string) => {
      if (navigationTo) {
        navigate(routesManager.masterData[navigationTo].update.getURL({ entryId }))
      }
    },
    [navigationTo, navigate],
  )

  if (hideWhenEmpty && !value?.length) {
    return null
  }

  return (
    <FormWrapTag tagMessage={tagMessage}>
      <Flex gap={8} direction="column" data-testid={dataTestId}>
        <WppLabel config={labelProps.labelConfig} tooltipConfig={labelProps.labelTooltipConfig} />
        <WppPillGroup type="display" ref={ref} {...rest}>
          <Flex wrap="wrap" gap={6}>
            {shownPills?.map(item => (
              <WppPill
                maxLength={pillMaxLength}
                label={item.label}
                disabled={disabled}
                onWppClick={() => handlePillClick(item.id)}
                removable={false}
                key={item.id}
              >
                <AvatarWithLabelPill label={item.label} avatarUrl={item.avatarUrl} />
              </WppPill>
            ))}
            {/* FIXME: using conditional rendering cases a bug in CL, that's why style used here */}
            <WppActionButton style={{ display: hasNextPage ? 'unset' : 'none' }} variant="primary" onClick={showMore}>
              {t('common.show_more')}
            </WppActionButton>
          </Flex>
        </WppPillGroup>
      </Flex>
    </FormWrapTag>
  )
}) as <T extends Entity>(props: Props<T>) => JSX.Element
