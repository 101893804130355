import { WppIconInfo, WppInlineMessage, WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentProps, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import styles from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper.module.scss'
import { EntityFormLikeAllFields, RouteModules } from 'types/masterData/utils'
import { mapEntityNames } from 'utils/entity'
import { routesManager } from 'utils/routesManager'

interface Props extends ComponentProps<typeof WppInlineMessage> {
  nonCurrentVersionSelected: boolean
  data?: EntityFormLikeAllFields
}

export const UpsertEntityMessageBlock = ({ data, nonCurrentVersionSelected, ...rest }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const moduleName = pathname?.split('/')?.[1] as RouteModules

  if (nonCurrentVersionSelected) {
    return (
      <WppInlineMessage
        size="m"
        message={t('change_history.old_version_prompt')}
        type="information"
        {...rest}
        data-testid="old-version-prompt"
      />
    )
  }

  if (data?.mergedInto) {
    return (
      <div className={styles.customInlineMessage} data-testid="merge-to-block">
        <WppIconInfo className={styles.mergeIcon} />
        <WppTypography type="s-body" className={styles.mergeMessage} data-testid="merge-to-message">
          {t('master_data.merge_entities.merge_alerts.merged_to', {
            entity: t(`common.master_data.lowercase_entities.${mapEntityNames[moduleName]}`),
          })}
        </WppTypography>
        <Link to={routesManager.masterData[moduleName].update.getURL({ entryId: data.mergedInto.id })}>
          <WppTypography type="s-body" className={styles.link} data-testid="merged-to-link-label">
            {data.mergedInto.name || data.mergedInto.displayName}
          </WppTypography>
        </Link>
      </div>
    )
  }

  if (data?.mergedWith?.length) {
    return (
      <div className={styles.customInlineMessage} data-testid="merge-from-block">
        <WppIconInfo className={styles.mergeIcon} />
        <WppTypography type="s-body" className={styles.mergeMessage}>
          {t('master_data.merge_entities.merge_alerts.merged_from', {
            entity: t(`common.master_data.lowercase_entities.${mapEntityNames[moduleName]}`, {
              count: data.mergedWith.length,
            }),
            count: data.mergedWith.length,
          })}
        </WppTypography>
        {data.mergedWith.map((mergedWithItem, index) => {
          const isLast = index === data.mergedWith.length - 1
          return (
            <Fragment key={mergedWithItem.id}>
              <Link to={routesManager.masterData[moduleName].update.getURL({ entryId: mergedWithItem.id })}>
                <WppTypography type="s-body" className={styles.link}>
                  {mergedWithItem.name || mergedWithItem.displayName}
                </WppTypography>
              </Link>
              {!isLast && (
                <WppTypography type="s-body" className={styles.separator}>
                  {', '}
                </WppTypography>
              )}
            </Fragment>
          )
        })}
      </div>
    )
  }
}
