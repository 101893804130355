import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Avatar } from 'components/common/avatar/Avatar'
import { AutocompleteExtendedOption } from 'components/form/formAutocomplete/utils'

interface Props extends AutocompleteExtendedOption {
  'data-testId'?: string
}

export const CustomOption = ({ label, avatarUrl, caption, 'data-testId': dataTestId }: Props) => (
  <>
    <Avatar slot="left" size="xs" name={label} src={avatarUrl!} data-testid={dataTestId} />
    <WppTypography slot="label" type="s-body">
      {label}
    </WppTypography>
    {caption && (
      <WppTypography slot="caption" type="s-body">
        {caption}
      </WppTypography>
    )}
  </>
)
