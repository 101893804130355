import { WppButton, WppIconSuccess, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'

interface Props {
  onClose: () => void
  importedSuccessful?: number
}

export const ImportEntitiesSuccessBody = ({ onClose, importedSuccessful }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <WppTypography slot="header" type="xl-heading">
        {t('import.modal.title_success')}
      </WppTypography>

      <Flex gap={4} slot="body">
        <WppIconSuccess />
        <WppTypography type="s-body">
          {t('import.modal.message_success', { records: importedSuccessful })}
        </WppTypography>
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton type="button" size="s" variant="primary" data-testid="error-cancel" onClick={onClose}>
          {t('import.modal.close')}
        </WppButton>
      </Flex>
    </>
  )
}
