import { facadeApi } from 'api'
import { ImportEntityResponse } from 'components/importEntitiesModal/utils'
import { RouteModules } from 'types/masterData/utils'

interface UploadFileParams {
  file: File
  module: RouteModules
}

export const importEntityApi = ({ file, module }: UploadFileParams) =>
  facadeApi.post<ImportEntityResponse>(
    `/${module}/bulk/csv`,
    { file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
