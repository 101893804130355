import { facadeApi } from 'api'
import { Geography, GeographyFormDTO } from 'types/masterData/geographies'

interface Params {
  geography: GeographyFormDTO
  mainId: string
  secondaryId: string
}

export const mergeGeographyApi = ({ geography, mainId, secondaryId }: Params) =>
  facadeApi.post<Geography>(`/geographies/${mainId}/merge-with/${secondaryId}`, geography)
