import { facadeApi } from 'api'
import { Client, ClientFormDTO } from 'types/masterData/clients'

interface Params {
  client: ClientFormDTO
  mainId: string
  secondaryId: string
}

export const mergeClientApi = ({ client, mainId, secondaryId }: Params) =>
  facadeApi.post<Client>(`/clients/${mainId}/merge-with/${secondaryId}`, client)
