import { useFormContext } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { useMarketsFormIsDirty } from 'pages/markets/upsert/hooks/useMarketsFormIsDirty'
import { RouteModules } from 'types/masterData/utils'

export const useIsFormDirty = () => {
  const { pathname } = useLocation()
  const moduleName = pathname?.split('/')?.[1] as RouteModules
  const {
    formState: { isDirty },
  } = useFormContext()
  const isMarketsDirty = useMarketsFormIsDirty()
  const isMarketsModule = moduleName === 'markets'

  return isMarketsModule ? isMarketsDirty : isDirty
}
