import { AvatarSize } from '@platform-ui-kit/components-library'
import { WppIconEdit } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { KeyboardEvent } from 'react'
import { useDropzone, ErrorCode, Accept } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import styles from 'components/common/avatar/avatarCropEdition/AvatarCropEdition.module.scss'
import {
  showManageAvatarSideModal,
  hideManageAvatarSideModal,
} from 'components/common/avatar/manageAvatarSideModal/ManageAvatarSideModal'
import { Flex } from 'components/common/flex/Flex'
import { useStableCallback } from 'hooks/useStableCallback'
import { useToast } from 'providers/toast/ToastProvider'
import { excludeFalsy } from 'utils/common'
import { blurActiveElement } from 'utils/dom'
import { bytes, formatBytes } from 'utils/files'

const DEF_MAX_SIZE = bytes(2, 'mb')

const DEF_ACCEPT = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/svg+xml': ['.svg'],
}

interface AvatarEditorSize {
  width?: number
  height?: number
}

interface Props {
  handleUploadFiles: (files: File[]) => void | Promise<void>
  handleDeleteFiles: () => void | Promise<void>
  title: string
  avatarName?: string
  avatarOriginalUrl?: MayBeNull<string>
  avatarThumbnailUrl?: MayBeNull<string>
  avatarEditorSize?: AvatarEditorSize
  maxSize?: number
  accept?: Accept
  avatarSize?: AvatarSize
  disabled?: boolean
}

export const AvatarCropEdition = ({
  handleUploadFiles,
  handleDeleteFiles,
  title,
  avatarName,
  avatarOriginalUrl,
  avatarThumbnailUrl,
  avatarEditorSize,
  maxSize = DEF_MAX_SIZE,
  accept = DEF_ACCEPT,
  avatarSize = 'xl',
  disabled = false,
}: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const hasAvatar = !!avatarOriginalUrl

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept,
    maxSize,
    disabled: disabled || hasAvatar,
    multiple: false,
    onDropRejected: rejections => {
      rejections.forEach(rejection => {
        const code = rejection.errors[0].code as ErrorCode

        if (code === ErrorCode.FileTooLarge) {
          enqueueToast({
            message: t('common.errors.file_too_large', {
              entity: formatBytes(maxSize),
            }),
            type: 'error',
          })
        }

        if (code === ErrorCode.FileInvalidType) {
          enqueueToast({
            message: t('common.errors.file_invalid_type', {
              entity: Object.values(accept).flat().join(', '),
            }),
            type: 'error',
          })
        }
      })
    },
    onDrop: acceptedFiles => {
      if (acceptedFiles.length) {
        handleOpenModal(acceptedFiles[0])
      }
    },
  })

  const handleDelete = useStableCallback(async () => {
    try {
      await handleDeleteFiles()

      hideManageAvatarSideModal()

      enqueueToast({
        message: t('common.toasts.delete', { entity: t('entities.avatar_image') }),
        type: 'success',
      })
    } catch (e) {
      enqueueToast({ message: t('common.errors.general'), type: 'error' })
      throw e
    }
  })

  const onSave = useStableCallback(async (getCroppedFile: () => Promise<File>) => {
    try {
      const croppedFile = await getCroppedFile()

      await handleUploadFiles([croppedFile, !hasAvatar && acceptedFiles[0]].filter(excludeFalsy))

      hideManageAvatarSideModal()

      enqueueToast({
        message: t('common.toasts.update', { entity: t('entities.avatar_image') }),
        type: 'success',
      })
    } catch (e) {
      enqueueToast({ message: t('common.errors.general'), type: 'error' })
      throw e
    }
  })

  function handleOpenModal(urlOrFile: string | File) {
    blurActiveElement()
    showManageAvatarSideModal({
      title,
      image: urlOrFile,
      onSave,
      ...(hasAvatar && { onDelete: handleDelete }),
      ...avatarEditorSize,
    })
  }

  return (
    <Flex
      {...getRootProps({
        className: clsx(styles.root, { [styles.disabled]: disabled }),
        ...(hasAvatar &&
          !disabled && {
            tabIndex: 0,
            onClickCapture: () => handleOpenModal(avatarOriginalUrl),
            onKeyDownCapture: ({ key }: KeyboardEvent) => {
              if (key === 'Enter') {
                handleOpenModal(avatarOriginalUrl)
              }
            },
          }),
      })}
    >
      <Flex
        className={clsx(styles.overlay, {
          [styles.isEmptyValue]: !disabled && !avatarName,
        })}
        justify="center"
        align="center"
      >
        <WppIconEdit className={styles.icon} />
      </Flex>
      <Avatar size={avatarSize} name={avatarName} src={avatarThumbnailUrl || avatarOriginalUrl || undefined} />
      <input {...getInputProps({ disabled: hasAvatar })} />
    </Flex>
  )
}
