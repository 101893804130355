import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'

import { Flex } from 'components/common/flex/Flex'

interface Props extends ComponentProps<typeof Flex> {}

const skeletonSize = Array.from({ length: 3 })
export const EntityChangeHistorySkeleton = ({ ...rest }: Props) => (
  <Flex direction="column" gap={12} data-testid="change-history-skeleton" {...rest}>
    {skeletonSize.map((_, index) => (
      <Flex key={index} gap={12} align="center" style={{ padding: '2px 8px' }}>
        <WppSkeleton height={32} width={32} variant="circle" />
        <Flex direction="column" gap={6} style={{ flexGrow: 1 }}>
          <WppSkeleton height={16} style={{ width: '50%' }} />
          <WppSkeleton height={16} style={{ width: '90%' }} />
        </Flex>
      </Flex>
    ))}
  </Flex>
)
