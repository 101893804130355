import { WppInput } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useSetState } from 'react-use'

import { useFetchAgenciesExportApi } from 'api/masterData/queries/useFetchAgenciesExportApi'
import { HeaderActionsList } from 'components/merge/list/headerActionsList/HeaderActionsList'
import { TableKey } from 'constants/table'
import { useDebounceSetSearch } from 'hooks/form/useDebounceSetSearch'
import { TableListAgencies } from 'pages/agencies/list/TableListAgencies'
import { TablePageActionButtons } from 'pages/components/tablePageActionButtons/TablePageActionButtons'
import { TablePageWrapper } from 'pages/components/tablePageWrapper/TablePageWrapper'
import styles from 'pages/components/tablePageWrapper/TablePageWrapper.module.scss'
import { CommonFilterState, MergeFilterState } from 'types/masterData/utils'

export const ListAgencies = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const stateObj = useSetState<CommonFilterState<MergeFilterState>>({
    isEmpty: false,
    isEmptySearch: false,
    search: searchParams.get('search') || '',
    searchInput: searchParams.get('search') || '',
    selectedRows: [],
  })
  const [state, setState] = stateObj
  const handleSearch = useDebounceSetSearch({ setState })

  return (
    <TablePageWrapper
      actionButtons={
        <TablePageActionButtons state={state} fetchExportFn={useFetchAgenciesExportApi} hasResults={!state.isEmpty} />
      }
      data-testid="agencies-table"
    >
      <div slot="header">
        <WppInput
          size="s"
          type="search"
          value={state.searchInput}
          onWppChange={handleSearch}
          className={styles.searchInput}
          placeholder={t('common.search')}
          data-testid="agency-search-input"
        />
      </div>
      <HeaderActionsList
        slot="actions"
        stateObj={stateObj}
        tableKey={TableKey.MASTER_DATA_AGENCIES}
        module="agencies"
      />
      <TableListAgencies stateObj={stateObj} />
    </TablePageWrapper>
  )
}
