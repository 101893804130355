import { ComponentProps, PropsWithChildren, useRef } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { FormRadio } from 'components/form/formRadio/FormRadio'

interface Props extends ComponentProps<typeof FormRadio> {
  flexAlign?: ComponentProps<typeof Flex>['align']
}

export const RADIO_INPUT_WIDTH = 45
export const LABEL_GAP = 8
export const RadioCustomLabel = ({
  name,
  className,
  flexAlign = 'center',
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  const radioRef = useRef<HTMLWppRadioElement>(null)

  return (
    <Flex align={flexAlign} gap={LABEL_GAP} onClick={() => radioRef.current?.click()} className={className}>
      <FormRadio ref={radioRef} name={name} {...rest} />
      {children}
    </Flex>
  )
}
