export enum TableKey {
  MASTER_DATA_AGENCIES = 'MASTER_DATA_AGENCIES',
  MASTER_DATA_BRANDS = 'MASTER_DATA_BRANDS',
  MASTER_DATA_CLIENTS = 'MASTER_DATA_CLIENTS',
  MASTER_DATA_CURRENCIES = 'MASTER_DATA_CURRENCIES',
  MASTER_DATA_CLIENTS_AGENCY = 'MASTER_DATA_CLIENTS_AGENCY',
  MASTER_DATA_CLIENTS_AGENCY_UPSERT = 'MASTER_DATA_CLIENTS_AGENCY_UPSERT',
  MASTER_DATA_INDUSTRIES = 'MASTER_DATA_INDUSTRIES',
  MASTER_DATA_MARKETS = 'MASTER_DATA_MARKETS',
  MASTER_DATA_GEOGRAPHIES = 'MASTER_DATA_GEOGRAPHIES',
}

export enum TableDefaults {
  LoaderStaleTime = 60 * 1000,
  CacheBlockSize = 50,
}
