// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uaV6v{position:relative}.uaV6v :first-child[name]{flex-grow:1}.CIgPp{position:absolute;right:0}`, "",{"version":3,"sources":["webpack://./src/components/form/formWrapTag/FormWrapTag.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CAEA,0BACE,WAAA,CAIJ,OACE,iBAAA,CACA,OAAA","sourcesContent":[".body {\n  position: relative;\n\n  :first-child[name] {\n    flex-grow: 1;\n  }\n}\n\n.tag {\n  position: absolute;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `uaV6v`,
	"tag": `CIgPp`
};
export default ___CSS_LOADER_EXPORT___;
