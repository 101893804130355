import { AutocompleteOptionId } from '@platform-ui-kit/components-library'
import { ComponentProps } from 'react'

import { LABEL_GAP, RADIO_INPUT_WIDTH, RadioCustomLabel } from 'components/common/radioCustomLabel/RadioCustomLabel'
import { Tooltip } from 'components/common/tooltip/Tooltip'
import { Truncate } from 'components/common/truncate/Truncate'

interface Props extends ComponentProps<typeof RadioCustomLabel> {
  cellWidth?: number
  label?: string
  value?: AutocompleteOptionId
  name: string
}

export const TableRadioCell = ({ cellWidth = 0, value, label, name, ...rest }: Props) => {
  const width = Math.max(cellWidth - RADIO_INPUT_WIDTH - LABEL_GAP, 200)

  return (
    <RadioCustomLabel value={value || label} name={name} required {...rest}>
      <Tooltip text={label} show showOnOverflow>
        <Truncate type="s-body" style={{ maxWidth: `${width}px` }}>
          {label}
        </Truncate>
      </Tooltip>
    </RadioCustomLabel>
  )
}
