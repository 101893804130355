import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { Brand } from 'types/masterData/brands'

export enum BrandsListSortBy {
  name = 'name',
  updatedAt = 'updatedAt',
  aliases = 'aliases',
  brand = 'brand',
  parents = 'parents',
}

type Params = PaginationParams<{
  search?: string
  sort?: string
}>

export type BrandsListResponse = PaginatedResponse<Brand>

export const fetchBrandsList = ({ search, page, sort, itemsPerPage }: Params) =>
  facadeApi.get<BrandsListResponse>('/brands', {
    params: {
      page,
      itemsPerPage,
      sort,
      filter: {
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
