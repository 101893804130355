import { useAsyncFn } from 'react-use'

import { useFetchBrandsApi } from 'api/masterData/queries/useFetchBrandsApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'
import { Brand } from 'types/masterData/brands'
import { excludeFalsy } from 'utils/common'

interface Params {
  search?: string
}

export const handleReloadBrandsListTable = async (entryId?: string) => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_BRANDS] }),
    queryClient.invalidateQueries({
      queryKey: [ApiQueryKeys.MASTER_DATA_BRAND, entryId && { entryId }].filter(excludeFalsy),
    }),
  ])
  tableActions.reload(TableKey.MASTER_DATA_BRANDS)
}

export const useBrandsLoader = ({ search }: Params) => {
  const handleFetchBrandsList = useFetchBrandsApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const [{ loading: isLoading, value }, loader] = useAsyncFn<TableInfiniteLoader<Brand>>(
    async ({ startRow, endRow, sortModel }) => {
      const size = endRow - startRow
      const [{ colId, sort }] = sortModel

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchBrandsList({
        itemsPerPage: size,
        sort: sort === 'asc' ? colId : `-${colId}`,
        search,
        page: endRow / size,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [search, handleFetchBrandsList],
  )

  return { isLoading, loader, hasResults: !!value?.totalRowsCount }
}
