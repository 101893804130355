import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentProps, ComponentType, ReactNode } from 'react'

import { is404Error } from 'api/utils'
import { Breadcrumbs } from 'components/breadcrumbs/Breadcrumbs'
import { Flex } from 'components/common/flex/Flex'
import { LoadingPage } from 'components/surface/loadingPage/LoadingPage'
import { NotFound } from 'pages/404/NotFound'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { FetchedTwoEntities, MergeCardProps } from 'types/masterData/mergeEntities'

interface Props extends ComponentProps<typeof Flex> {
  title: string
  actionButtons: ReactNode
  selectMainEntity: ComponentType<MergeCardProps>
  compareEntities: ComponentType<MergeCardProps>
  previewResult: ComponentType<MergeCardProps>
  className?: string
  fetchEntities: FetchedTwoEntities
  children?: never
}

export const MergeEntityWrapper = ({
  title,
  selectMainEntity: SelectMainEntity,
  previewResult: PreviewResult,
  compareEntities: CompareEntities,
  actionButtons,
  className,
  fetchEntities,
  ...rest
}: Props) => {
  if (fetchEntities.isLoading) {
    return <LoadingPage />
  }

  if (fetchEntities.isDeleted) {
    return <NotFound />
  }

  if (fetchEntities.errors?.some(is404Error)) {
    return <NotFound />
  }

  return (
    <Flex direction="column" gap={24} className={clsx(styles.container, className)} {...rest}>
      <Flex direction="column" gap={8}>
        <Breadcrumbs />
        <Flex justify="between" gap={12} align="center">
          <WppTypography tag="h1" type="3xl-heading">
            {title}
          </WppTypography>
          {actionButtons && <Flex gap={12}>{actionButtons}</Flex>}
        </Flex>
      </Flex>

      <Flex gap={24} className={styles.blockWrapper}>
        <Flex direction="column" gap={24} className={styles.leftBlock}>
          <SelectMainEntity size="l" {...fetchEntities} />

          <CompareEntities size="l" className={clsx(styles.fullHeightCard, styles.tableWrapper)} {...fetchEntities} />
        </Flex>
        <PreviewResult size="l" className={clsx(styles.rightBlock, styles.fullHeightCard)} {...fetchEntities} />
      </Flex>
    </Flex>
  )
}
