import isEqual from 'lodash.isequal'
import { useFormContext } from 'react-hook-form'

import { MarketForm, MarketType } from 'types/masterData/markets'

const prepareValues = (values?: MarketForm): Partial<MarketForm> => {
  if (!values) return {}
  const { isoAlpha2, isoAlpha3, currencyIds, ...restValues } = values
  const isCountryType = restValues.type === MarketType.COUNTRY
  return {
    ...restValues,
    ...(isCountryType
      ? {
          isoAlpha2,
          isoAlpha3,
          currencyIds,
        }
      : {}),
  }
}

export const useMarketsFormIsDirty = () => {
  const {
    getValues,
    formState: { defaultValues },
  } = useFormContext<MarketForm>()
  const values = getValues()

  return !isEqual(prepareValues(values), prepareValues(defaultValues as MarketForm))
}
