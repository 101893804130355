import { MayBeNull } from '@wpp-open/core'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSetState, useUpdateEffect } from 'react-use'

import { UpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { ChangeHistory } from 'types/masterData/changeHistory'
import { EntityFormLikeAllFields } from 'types/masterData/utils'

export interface EntityProviderState {
  selectedVersion: MayBeNull<ChangeHistory>
  selectedVersionFields: Partial<Record<keyof EntityFormLikeAllFields, string>>
  nonCurrentVersionSelected: boolean
}
export interface SelectVersionProps {
  version: MayBeNull<ChangeHistory>
}

export const initialValue: EntityProviderState = {
  selectedVersion: null,
  selectedVersionFields: {},
  nonCurrentVersionSelected: false,
}

export const UpsertEntityProvider = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const [contextState, setContextState] = useSetState<EntityProviderState>(initialValue)
  const { entryId } = useParams()

  useUpdateEffect(() => {
    setContextState(initialValue)
  }, [entryId])

  const handleSelectVersion = ({ version }: SelectVersionProps) =>
    setContextState(({ selectedVersion }) => {
      const isAlreadySelected = selectedVersion?.id === version?.id
      if (!version || isAlreadySelected) return initialValue

      const isCurrentVersion = version.isCurrentVersion
      const namedFields = version.fields.map(item => [
        item,
        isCurrentVersion
          ? t('change_history.versions.current')
          : t('change_history.versions.version_number', { number: version.index }),
      ])
      return {
        selectedVersion: version,
        selectedVersionFields: Object.fromEntries(namedFields),
        nonCurrentVersionSelected: !version.isCurrentVersion,
      }
    })

  return (
    <UpsertEntityContext.Provider value={{ contextState, setContextState, handleSelectVersion }}>
      {children}
    </UpsertEntityContext.Provider>
  )
}
