import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'

import { CommonMergeValues, ENTRY_NAME } from 'types/masterData/mergeEntities'
import { EntityFormLikeAllFields } from 'types/masterData/utils'

interface Props {
  formValues: CommonMergeValues
  field: keyof EntityFormLikeAllFields
  values: any
  valueGetterFn?: (arg: AutocompleteDefaultOption) => string
}

export const convertFormValuesFieldToFormDTO = ({
  formValues,
  field,
  values,
  valueGetterFn = item => item.label,
}: Props) => {
  const selectedValuesSet = new Set<string>()
  formValues.tableRowDataFields.forEach(entity => {
    if (entity.id !== field) return
    ENTRY_NAME.forEach((entryName, entryIndex) => {
      const data = entity[entryName].data as AutocompleteDefaultOption
      const isSelected = values?.[field]?.[entryIndex]?.[entity.index!]
      if (isSelected) {
        valueGetterFn(data) && selectedValuesSet.add(valueGetterFn(data))
      }
    })
  })
  return [...selectedValuesSet]
}
