import { facadeApi } from 'api'
import { Geography, GeographyFormDTO } from 'types/masterData/geographies'

interface Params {
  id: string
  geography: GeographyFormDTO
}

export const editGeographyApi = ({ id, geography }: Params) =>
  facadeApi.patch<Geography>(`/geographies/${id}`, geography)
