import { AutocompleteOption } from '@platform-ui-kit/components-library'
import { MayBeNull } from '@wpp-open/core'
import { FieldError } from 'react-hook-form'

import { FormAutocompleteProps } from 'components/form/formAutocomplete/FormAutocomplete'
import { excludeFalsy } from 'utils/common'

export type FormAutocompleteInfiniteProps<T extends AutocompleteOption> = Omit<
  FormAutocompleteProps<T>,
  | 'infinite'
  | 'loadMore'
  | 'infiniteLastPage'
  | 'selectAll'
  | 'onWppSearchValueChange'
  | 'onWppFocus'
  | 'onWppBlur'
  | 'simpleSearch'
  | 'options'
  | 'loading'
>

export interface AutocompleteExtendedOption {
  id: string
  label: string
  avatarUrl?: MayBeNull<string>
  caption?: string
}

export const getFieldErrorMessage = (errors?: FieldError | Record<string, FieldError>[]) => {
  if (Array.isArray(errors)) {
    const nonEmptyErrors = errors.filter(excludeFalsy)
    const firstError = Object.entries(nonEmptyErrors?.[0]) as [string, FieldError][]
    return firstError?.[0]?.[1]?.message
  }
  return errors?.message
}
