import { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'

interface Props extends HTMLAttributes<HTMLImageElement> {
  src: string
}

export const EntityLogo = ({ src, ...rest }: Props) => {
  const { t } = useTranslation()
  if (!src) return null
  return <img src={src} className={styles.logo} alt={t('master_data.merge_entities.fields.logoThumbnail')} {...rest} />
}
