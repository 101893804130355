import { convertFormValuesFieldToFormDTO } from 'components/merge/utils'
import { defaultFormValues } from 'pages/industries/merge/form/defaultValues'
import { ConvertIndustryFormProps, MergeIndustriesForm } from 'pages/industries/merge/form/types'
import { convertToTwoEntityValues } from 'pages/industries/merge/twoEntities/utils'
import { Industry, IndustryFormDTO } from 'types/masterData/industries'
import { arrayFieldDefaultValue } from 'utils/merge/utils'

export const convertToForm = ([industry1, industry2]: Industry[]): MergeIndustriesForm => {
  const defaultParentId = industry1.parent?.id || industry2.parent?.id

  return {
    ...defaultFormValues,
    name: industry1.name,
    aliases: arrayFieldDefaultValue(industry1.aliases.length, industry2.aliases.length),
    parentId: defaultParentId || null,
  }
}

export const convertFormToFormDto = ({ values, data }: ConvertIndustryFormProps): IndustryFormDTO => {
  const formValues = convertToTwoEntityValues(data)
  const name = values.isNewName ? values.newName : values.name

  const aliases = convertFormValuesFieldToFormDTO({
    formValues,
    values,
    field: 'aliases',
  })

  return {
    name,
    aliases,
    parentId: values.parentId,
  }
}
