// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZxnZM{display:inline-block;max-width:100%}.ZxnZM::part(anchor){max-width:100%}.G8RtH{max-width:100%}`, "",{"version":3,"sources":["webpack://./src/components/common/tooltip/Tooltip.module.scss"],"names":[],"mappings":"AAAA,OACE,oBAAA,CACA,cAAA,CAEA,qBACE,cAAA,CAIJ,OACE,cAAA","sourcesContent":[".tooltip {\n  display: inline-block;\n  max-width: 100%;\n\n  &::part(anchor) {\n    max-width: 100%;\n  }\n}\n\n.childWrap {\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `ZxnZM`,
	"childWrap": `G8RtH`
};
export default ___CSS_LOADER_EXPORT___;
