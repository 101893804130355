import { WppAutocomplete } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'

type AutocompleteDropdownConfig = NonNullable<ComponentProps<typeof WppAutocomplete>['dropdownConfig']>
type InstanceType = Parameters<NonNullable<AutocompleteDropdownConfig['onShow']>>[0]

interface Props {
  isFixedPositioningStrategy?: boolean
  dropdownConfig?: AutocompleteDropdownConfig
}

export const getDropdownConfig = ({
  dropdownConfig,
  isFixedPositioningStrategy,
}: Props): AutocompleteDropdownConfig => {
  if (!isFixedPositioningStrategy) return dropdownConfig!
  return {
    ...dropdownConfig,
    popperOptions: {
      ...dropdownConfig?.popperOptions,
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          enabled: false,
        },
        ...(dropdownConfig?.popperOptions?.modifiers ? dropdownConfig.popperOptions.modifiers : []),
      ],
    },
    onShow(instance: InstanceType) {
      const dropdownHeight = parseFloat(document.body.style.getPropertyValue('--wpp-autocomplete-height-m') || '250')
      const spaceGap = 10
      const needHeight = dropdownHeight + spaceGap
      const spaceBelow = window.innerHeight - instance.reference.getBoundingClientRect().bottom
      const spaceAbove = instance.reference.getBoundingClientRect().top

      if (spaceBelow < needHeight && spaceAbove > needHeight) {
        instance.setProps({ placement: 'top' })
      } else {
        instance.setProps({ placement: 'bottom' })
      }
    },
  }
}
