import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SvgNoRecords } from 'components/svg/network/SvgNoRecords'

export const useTableNoMergeRowsOverlay = () => {
  const { t } = useTranslation()
  return useCallback(
    () => (
      <Flex direction="column" align="center" gap={12}>
        <SvgNoRecords />

        <WppTypography type="m-strong">{t('master_data.merge_entities.table.empty')}</WppTypography>
      </Flex>
    ),
    [t],
  )
}
