import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { ListClients } from 'pages/clients/list/ListClients'
import { MergeClients } from 'pages/clients/merge/MergeClients'
import { UpsertClient } from 'pages/clients/upsert/UpsertClient'
import { UpsertEntityProvider } from 'pages/components/upsertEntityWrapper/components/UpsertEntityProvider'
import { routesManager } from 'utils/routesManager'

const WrappedUpsertClient = (
  <UpsertEntityProvider>
    <UpsertClient />
  </UpsertEntityProvider>
)

export const Clients = () => (
  <Routes>
    <Route path={routesManager.masterData.clients.root.relativePattern} index element={<ListClients />} />
    <Route path={routesManager.masterData.clients.merge.relativePattern} element={<MergeClients />} />
    <Route path={routesManager.masterData.clients.create.relativePattern} element={WrappedUpsertClient} />
    <Route path={routesManager.masterData.clients.update.relativePattern} element={WrappedUpsertClient} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
