import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { Agency, AgencyForm, AgencyFormDTO } from 'types/masterData/agencies'
import { serviceNameRegExp } from 'utils/regexp'
import { autocompleteOptionType, zodEmptyString } from 'utils/zod'

export const defaultValues: AgencyForm = {
  displayName: '',
  serviceName: '',
  shortDescription: '',
  description: '',
  navigatorUrl: '',
  aliases: [],
  domains: [],
  clientIds: [],
  parentId: [],
  logoThumbnail: null,
  logoOriginal: null,
  meta: null,
  children: [],
}

export const convertToForm = (values: Agency): AgencyForm =>
  values && {
    ...values,
    aliases: (values?.aliases || []).map(item => ({ id: item, label: item })),
    domains: (values?.domains || []).map(item => ({ id: item, label: item })),
    parentId: values?.parent ? [{ id: values.parent.id, label: values.parent.displayName }] : [],
    meta: values?.meta ? JSON.stringify(values.meta) : null,
  }

export const convertFormToFormDto = (values: AgencyForm): AgencyFormDTO => ({
  ...values,
  description: values.description || null,
  shortDescription: values.shortDescription || null,
  navigatorUrl: values.navigatorUrl || null,
  aliases: values.aliases.map(item => item.id.toString()),
  domains: values.domains.map(item => item.id.toString()),
  parentId: values.parentId.length ? values.parentId[0].id.toString() : null,
})

export const displayNameFieldCharacterMaxCount = 256
export const displayNameFieldCharacterMinCount = 3
export const serviceNameFieldCharacterMinCount = 3
export const shortDescriptionFieldCharacterMinCount = 3
export const descriptionFieldCharacterMinCount = 3
export const serviceNameFieldCharacterMaxCount = 100
export const aliasesFieldCharacterMinCount = 2
export const aliasesFieldCharacterMaxCount = 100
export const shortDescriptionFieldCharacterMaxCount = 120
export const descriptionFieldCharacterMaxCount = 800
export const navigationUrlFieldCharacterMaxCount = 2083
export const descriptionFieldCharacterWarningThreshold = 700
const domainRegExp = /^((?!https:\/\/)[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i

export const useAgencyValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        logoOriginal: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        logoThumbnail: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        displayName: zod
          .string()
          .trim()
          .min(
            displayNameFieldCharacterMinCount,
            t('master_data.validation.display_name.min', {
              entity: t('common.master_data.entities.agency'),
              characterCount: displayNameFieldCharacterMinCount,
            }),
          )
          .max(
            displayNameFieldCharacterMaxCount,
            t('master_data.validation.display_name.max', {
              entity: t('common.master_data.entities.agency'),
              characterCount: displayNameFieldCharacterMaxCount,
            }),
          ),
        shortDescription: zod
          .string()
          .trim()
          .min(
            shortDescriptionFieldCharacterMinCount,
            t('master_data.validation.short_description.min', {
              entity: t('common.master_data.entities.agency'),
              characterCount: shortDescriptionFieldCharacterMinCount,
            }),
          )
          .max(
            shortDescriptionFieldCharacterMaxCount,
            t('master_data.validation.short_description.max', {
              entity: t('common.master_data.entities.agency'),
              characterCount: shortDescriptionFieldCharacterMaxCount,
            }),
          )
          .nullable()
          .or(zodEmptyString),
        description: zod
          .string()
          .trim()
          .min(
            descriptionFieldCharacterMinCount,
            t('master_data.validation.description.min', {
              entity: t('common.master_data.entities.agency'),
              characterCount: descriptionFieldCharacterMinCount,
            }),
          )
          .max(
            descriptionFieldCharacterMaxCount,
            t('master_data.validation.description.max', {
              entity: t('common.master_data.entities.agency'),
              characterCount: descriptionFieldCharacterMaxCount,
            }),
          )
          .nullable()
          .or(zodEmptyString),
        navigatorUrl: zod
          .string()
          .trim()
          .url(t('master_data.validation.navigator_url.not_valid'))
          .max(
            navigationUrlFieldCharacterMaxCount,
            t('master_data.validation.navigator_url.max', {
              entity: t('common.master_data.entities.agency'),
              characterCount: navigationUrlFieldCharacterMaxCount,
            }),
          )
          .nullable()
          .or(zodEmptyString),
        serviceName: zod
          .string()
          .trim()
          .regex(serviceNameRegExp, t('master_data.validation.service_name.not_valid'))
          .min(
            serviceNameFieldCharacterMinCount,
            t('master_data.validation.service_name.min', {
              entity: t('common.master_data.entities.agency'),
              characterCount: serviceNameFieldCharacterMinCount,
            }),
          )
          .max(
            serviceNameFieldCharacterMaxCount,
            t('master_data.validation.service_name.max', {
              entity: t('common.master_data.entities.agency'),
              characterCount: serviceNameFieldCharacterMaxCount,
            }),
          ),
        aliases: zod.array(
          zod.object({
            id: zod
              .string()
              .min(aliasesFieldCharacterMinCount, {
                message: t('master_data.validation.aliases.min', {
                  characterCount: aliasesFieldCharacterMinCount,
                }),
              })
              .max(aliasesFieldCharacterMaxCount, {
                message: t('master_data.validation.aliases.max', {
                  characterCount: aliasesFieldCharacterMaxCount,
                }),
              }),
            label: zod.string(),
          }),
        ),
        domains: zod.array(
          zod.object({
            id: zod.string().regex(domainRegExp, {
              message: t('master_data.validation.domains.invalid'),
            }),
            label: zod.string(),
          }),
        ),
        parentId: zod.union([zod.array(autocompleteOptionType), zod.string()]),
      }),
    [t],
  )
}
