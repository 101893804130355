// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phO6U{opacity:0}.phO6U .ag-root.ag-unselectable{-webkit-user-select:auto;user-select:auto}.phO6U .ag-overlay-wrapper{padding-top:var(--ag-row-height);pointer-events:auto}.phO6U .ag-tooltip{--ag-tooltip-background-color: var(--wpp-white-color)}.phO6U .ag-cell-value{overflow:hidden}.phO6U .ag-cell-value::after{display:block;content:""}.XuyKO:not(.infinite-default-cell),.loading-more-row .XuyKO{display:inline-flex;align-items:center}.icVtL{opacity:1}.nMuZa{cursor:pointer}.M3zeL{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/components/common/table/Table.module.scss"],"names":[],"mappings":"AAAA,OA2BE,SAAA,CAzBE,gCACE,wBAAA,CAAA,gBAAA,CAGF,2BACE,gCAAA,CACA,mBAAA,CAGF,mBACE,qDAAA,CAGF,sBACE,eAAA,CAIA,6BACE,aAAA,CACA,UAAA,CASR,4DAEE,mBAAA,CACA,kBAAA,CAGF,OACE,SAAA,CAGF,OACE,cAAA,CAGF,OACE,eAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".root {\n  :global {\n    .ag-root.ag-unselectable {\n      user-select: auto;\n    }\n\n    .ag-overlay-wrapper {\n      padding-top: var(--ag-row-height);\n      pointer-events: auto;\n    }\n\n    .ag-tooltip {\n      --ag-tooltip-background-color: var(--wpp-white-color);\n    }\n\n    .ag-cell-value {\n      overflow: hidden;\n\n      // Prevent native Safari tooltip when cell text is truncated\n      // Has no effect on other browsers\n      &::after {\n        display: block;\n        content: '';\n      }\n    }\n  }\n\n  opacity: 0;\n}\n\n// TableInfinite always wraps a loading cellRenderer, so it adds a custom global class instead.\n.customCell:not(:global(.infinite-default-cell)),\n:global(.loading-more-row) .customCell {\n  display: inline-flex;\n  align-items: center;\n}\n\n.ready {\n  opacity: 1;\n}\n\n.clickableRow {\n  cursor: pointer;\n}\n\n.header {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `phO6U`,
	"customCell": `XuyKO`,
	"ready": `icVtL`,
	"clickableRow": `nMuZa`,
	"header": `M3zeL`
};
export default ___CSS_LOADER_EXPORT___;
