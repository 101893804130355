import { WppTextareaInput } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { FormWrapTag } from 'components/form/formWrapTag/FormWrapTag'
import { useField } from 'hooks/form/useField'
import { useProvideFieldFocus } from 'hooks/form/useProvideFieldFocus'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppTextareaInput>, 'value' | 'onChange' | 'onWppChange'> {
  name: string
  tagMessage?: string
}

export const FormTextareaInput = forwardRef<HTMLWppTextareaInputElement, Props>(
  ({ name, message, messageType, onWppBlur, tagMessage, ...rest }, ref) => {
    const innerRef = useRef<HTMLWppTextareaInputElement>(null)

    const {
      field: { ref: fieldRef, value, onChange, onBlur },
      fieldState: { isTouched, error },
    } = useField({ name })

    useProvideFieldFocus({
      fieldRef,
      setFocus: () => innerRef.current?.setFocus(),
    })

    const errorText = error?.message
    const shouldShowError = isTouched && !!errorText

    return (
      <FormWrapTag tagMessage={tagMessage}>
        <WppTextareaInput
          {...rest}
          ref={mergeRefs([innerRef, ref])}
          name={name}
          value={value}
          onWppChange={event => onChange(event.detail.value)}
          onWppBlur={e => {
            onBlur()
            onWppBlur?.(e)
          }}
          message={shouldShowError ? errorText : message}
          messageType={shouldShowError ? 'error' : messageType}
        />
      </FormWrapTag>
    )
  },
)
