import { WppLabel } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { Hierarchy } from 'components/hierarchyBreadcrumbs/HierarchyBreadcrumbs'
import { HierarchyTree } from 'components/hierarchyTree/HierarchyTree'
import { EntityFormLikeAllFields, RouteModules } from 'types/masterData/utils'
import { mapEntityNames } from 'utils/entity'

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const EntityDetailsHierarchy = ({ ...rest }: Props) => {
  const { entryId } = useParams()

  const { watch } = useFormContext<EntityFormLikeAllFields>()
  const [hierarchyValue, nameValue, displayNameValue] = watch(['hierarchy', 'name', 'displayName'])
  const name = nameValue || displayNameValue

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const modulePath = pathname.split('/')?.[1] as RouteModules

  const hierarchy = useMemo<Hierarchy[]>(() => {
    const currentItem: Hierarchy = { id: entryId!, name: name! }
    return [currentItem, ...(hierarchyValue || [])]
  }, [hierarchyValue, name, entryId])

  if (!hierarchyValue?.length) return null

  return (
    <div {...rest}>
      <WppLabel
        config={{
          text: t('info_cards.entity_structure', {
            entity: t(`common.master_data.entities.${mapEntityNames[modulePath]}`),
          }),
        }}
      />
      <HierarchyTree data={hierarchy} navigationTo={modulePath} />
    </div>
  )
}
