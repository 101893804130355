import { WppInputCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { InputChangeEventDetail } from '@platform-ui-kit/components-library/dist/types/components/wpp-input/types'
import { useSearchParams } from 'react-router-dom'
import { useSetState } from 'react-use'

import { Delay } from 'constants/delay'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { CommonFilterState } from 'types/masterData/utils'

interface Props<T extends CommonFilterState = CommonFilterState> {
  setState: ReturnType<typeof useSetState<T>>[1]
}

export const useDebounceSetSearch = ({ setState }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setSearchDebounced = useDebounceFn((search?: string) => {
    const searchTerm = search?.trim() || ''
    setState({ search: searchTerm })

    const params = new URLSearchParams(searchParams)
    if (searchTerm) {
      params.set('search', searchTerm)
    } else {
      params.delete('search')
    }
    setSearchParams(params)
  }, Delay.Search)

  return useStableCallback(({ detail: { value } }: WppInputCustomEvent<InputChangeEventDetail>) => {
    setState({ searchInput: value })
    setSearchDebounced(value)
  })
}
