import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { defaultFormValues } from 'pages/agencies/merge/form/defaultValues'
import { useAgencyValidationScheme } from 'pages/agencies/upsert/utils'

export const useAgenciesMergeValidationScheme = () => {
  const { t } = useTranslation()
  const validationScheme = useAgencyValidationScheme()
  const nameSchema = validationScheme.shape.displayName

  const allFields = Object.fromEntries(Object.keys(defaultFormValues).map(field => [field, zod.any()]))

  return useMemo(
    () =>
      zod
        .object({
          ...allFields,
          serviceName: zod.string(),
          isNewName: zod.boolean(),
          newName: zod.string().optional(),
        })
        .refine(
          data => {
            if (data.isNewName) {
              const result = nameSchema.safeParse(data.newName)
              if (!result.success) {
                throw new zod.ZodError(
                  result.error.errors.map(error => ({
                    ...error,
                    path: ['newName'],
                  })),
                )
              }
            }
            return true
          },
          {
            message: t('master_data.merge_entities.validation.required'),
            path: ['newName'],
          },
        ),
    [t, nameSchema, allFields],
  )
}
