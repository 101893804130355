import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { ReadonlyPills } from 'components/common/readonlyPills/ReadonlyPills'
import { MergeClientsForm } from 'pages/clients/merge/form/types'
import { convertFormToPreview } from 'pages/clients/merge/preview-utils'
import { EntityLogo } from 'pages/components/mergeEntityWrapper/components/EntityLogo'
import { EntityPills } from 'pages/components/mergeEntityWrapper/components/EntityPills'
import { EntityTextPreview } from 'pages/components/mergeEntityWrapper/components/EntityTextPreview'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { Client } from 'types/masterData/clients'
import { MergeCardProps } from 'types/masterData/mergeEntities'

export const PreviewResult = ({ data, ...rest }: MergeCardProps) => {
  const { t } = useTranslation()
  const { watch } = useFormContext<MergeClientsForm>()
  const formValues = watch()

  const preview = convertFormToPreview({ values: formValues, data: data as Client[] })

  return (
    <WppCard {...rest}>
      <WppTypography type="l-strong" slot="header">
        {t('master_data.merge_entities.titles.preview', { order: 3 })}
      </WppTypography>
      <WppTypography type="m-body" className={styles.descriptionText}>
        {t('master_data.merge_entities.descriptions.preview')}
      </WppTypography>
      <Flex direction="column" gap={24}>
        <EntityLogo src={preview.logoThumbnail?.url!} />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.entity_name', {
            entity: t('common.master_data.entities.client'),
          })}
          text={preview.name!}
          hasEmptyState
        />

        <EntityPills data={preview.aliases!} label={t('master_data.merge_entities.fields.aliases')} />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.parent_entity', {
            entity: t('common.master_data.lowercase_entities.client'),
          })}
          text={preview.parent?.name!}
        />

        <EntityPills data={preview.domains} label={t('master_data.merge_entities.fields.domains')} />

        <ReadonlyPills
          labelConfig={{ text: t('master_data.merge_entities.fields.children') }}
          value={preview.children!}
          navigationTo="clients"
        />

        <ReadonlyPills
          labelConfig={{ text: t('master_data.merge_entities.fields.brands') }}
          value={preview.brands!}
          navigationTo="brands"
        />
      </Flex>
    </WppCard>
  )
}
