import { WppRadio, WppRadioGroup } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import { useField } from 'hooks/form/useField'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppRadioGroup>, 'onChange' | 'onWppChange' | 'value'> {
  name: string
  values: string[]
  disabled?: boolean
}

export const FormRadioGroup = forwardRef<HTMLWppRadioGroupElement, Props>(
  ({ name, values, disabled, children, onWppBlur, ...rest }, ref) => {
    const innerRef = useRef<HTMLWppRadioGroupElement>(null)

    const {
      field: { value, onChange, onBlur },
    } = useField({ name })

    return (
      <WppRadioGroup
        {...rest}
        ref={mergeRefs([innerRef, ref])}
        value={value}
        onWppChange={event => onChange(event.detail.value)}
        onWppBlur={e => {
          onBlur()
          onWppBlur?.(e)
        }}
      >
        <Flex gap={12} wrap="wrap">
          {values.map(item => (
            <WppRadio disabled={disabled} name={name} value={item} key={item} labelConfig={{ text: item }} required />
          ))}
        </Flex>
      </WppRadioGroup>
    )
  },
)
