import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentProps, PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/tablePageWrapper/TablePageWrapper.module.scss'

interface Props extends ComponentProps<typeof WppCard> {
  actionButtons?: ReactNode
}

export const TablePageWrapper = ({ children, actionButtons, ...rest }: PropsWithChildren<Props>) => {
  const { t } = useTranslation()
  return (
    <>
      <Flex justify="between" align="center" className={styles.actionsContainer}>
        <WppTypography type="3xl-heading">{t('common.master_data_title')}</WppTypography>
        {actionButtons && <Flex gap={8}>{actionButtons}</Flex>}
      </Flex>

      <WppCard className={styles.pageCardRoot} size="xl" {...rest}>
        {children}
      </WppCard>
    </>
  )
}
