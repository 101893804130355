import { SelectionChangedEvent } from 'ag-grid-community/dist/lib/events'
import { useSetState } from 'react-use'

import { useStableCallback } from 'hooks/useStableCallback'
import { EntityCommonFields } from 'types/masterData/utils'

interface CommonState<T extends EntityCommonFields> {
  selectedRows: T['id'][]
}

interface Props<T extends EntityCommonFields> {
  setState: ReturnType<typeof useSetState<CommonState<T>>>[1]
}

export const MAX_SELECTED_ROWS = 2
export const useOnSelectionChanged = <T extends EntityCommonFields>({ setState }: Props<T>) =>
  useStableCallback(({ api }: SelectionChangedEvent<T>) => {
    const selectedNodes = api.getSelectedNodes()
    if (selectedNodes.length > MAX_SELECTED_ROWS) {
      // if more than max rows are selected - unselect the first one
      selectedNodes[0].setSelected(false)
    }
    setState({
      selectedRows: selectedNodes.map(item => item.data?.id!),
    })
  })
