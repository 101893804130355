import { WppTabCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { TabChangeEventDetail } from '@platform-ui-kit/components-library/dist/types/components/wpp-tabs/types'
import { WppTab, WppTabs } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Tooltip } from 'components/common/tooltip/Tooltip'
import { showUnsavedConfirmationModal } from 'components/unsavedConfirmationModal/UnsavedConfirmationModal'
import { useStableCallback } from 'hooks/useStableCallback'
import styles from 'pages/agencies/upsert/tabs/UpsertTabs.module.scss'
import { useUpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { LocationState } from 'types/masterData/state'

interface Props extends Omit<ComponentProps<typeof WppTabs>, 'value'> {}

export enum Tabs {
  default = 'default',
  clients = 'clients',
}

export const UpsertTabs = ({ ...rest }: Props) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const openedTab = searchParams.get('type') || Tabs.default
  const { entryId } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const locationState: LocationState = state
  const {
    contextState: { nonCurrentVersionSelected },
  } = useUpsertEntityContext()

  const {
    formState: { isDirty },
    reset,
  } = useFormContext()

  const handleNavigate = (value: Tabs) => {
    const navigateSearchParams = new URLSearchParams(searchParams)

    if (value !== Tabs.default) {
      navigateSearchParams.set('type', value)
    } else {
      navigateSearchParams.delete('type')
    }
    navigate(
      { search: navigateSearchParams.toString() },
      {
        state: { ...(!!locationState?.filters && { filters: locationState.filters }) },
      },
    )
  }

  const handleChange = useStableCallback((event: WppTabCustomEvent<TabChangeEventDetail>) => {
    const value = event.detail.value as Tabs
    if (!isDirty) {
      return handleNavigate(value)
    }

    showUnsavedConfirmationModal({
      onSubmit: () => {
        reset()
        handleNavigate(value)
      },
      onCancel: () => {
        handleNavigate(Tabs.default)
      },
    })
  })

  return (
    <WppTabs value={openedTab} {...rest} data-testid="agencies-upsert-tabs">
      <WppTab className={styles.tab} value={Tabs.default} onWppChangeTabControlItem={handleChange}>
        {t('navigation.agency.tabs.details.label')}
      </WppTab>
      <Tooltip text={t('navigation.agency.tabs.clients.hint')} show={!entryId}>
        <WppTab
          className={styles.tab}
          value={Tabs.clients}
          disabled={!entryId || nonCurrentVersionSelected}
          onWppChangeTabControlItem={handleChange}
        >
          {t('navigation.agency.tabs.clients.label')}
        </WppTab>
      </Tooltip>
    </WppTabs>
  )
}
