import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { createUseQuery } from 'api/common/createUseQuery'
import { QueryFetcher } from 'api/common/types'
import { useClientsDataLoading } from 'hooks/masterData/useClientsDataLoading'
import { EntityAllFields } from 'types/masterData/utils'
import { excludeFalsy } from 'utils/common'

type Response = EntityAllFields
export type QueryFn<F extends Response> = ReturnType<typeof createUseQuery<QueryFetcher<(arg: F) => F>, F>>
interface Props<F extends Response, M extends unknown> {
  queryFn: QueryFn<F>
  getValuesFn: ({
    isSuccess,
    data,
    clientsData,
  }: {
    isSuccess: boolean
    data: F[]
    clientsData?: AutocompleteDefaultOption[]
  }) => M
}

export const useFetchTwoEntities = <P extends Response, M extends unknown>({ queryFn, getValuesFn }: Props<P, M>) => {
  const { entry1Id, entry2Id } = useParams()

  const fetchEntity1 = queryFn({
    params: { entryId: entry1Id! },
    enabled: !!entry1Id,
  })
  const fetchEntity2 = queryFn({
    params: { entryId: entry2Id! },
    enabled: !!entry2Id,
  })

  const isEntitiesSuccess = fetchEntity1.isSuccess && fetchEntity2.isSuccess
  const isEntitiesLoading = fetchEntity1.isLoading || fetchEntity2.isLoading

  const hasClientIds = fetchEntity1?.data?.clientIds?.length || fetchEntity2?.data?.clientIds?.length
  const loadClientsEnabled = isEntitiesSuccess && !isEntitiesLoading && hasClientIds

  const { isClientsDataLoading, isClientsDataSuccess, clientsData } = useClientsDataLoading({
    entry1Id,
    entry2Id,
    loadClientsEnabled: !!loadClientsEnabled,
  })

  const isLoading = loadClientsEnabled ? isEntitiesLoading || isClientsDataLoading : isEntitiesLoading
  const isSuccess = loadClientsEnabled ? isEntitiesSuccess && isClientsDataSuccess : isEntitiesSuccess

  const isDeleted = !!fetchEntity1?.data?.deletedAt || !!fetchEntity2?.data?.deletedAt
  // one of the entities is deleted (might be already merged)

  const values = useMemo(
    () => getValuesFn({ isSuccess, data: [fetchEntity1.data, fetchEntity2.data], clientsData }),
    [isSuccess, fetchEntity1.data, fetchEntity2.data, getValuesFn, clientsData],
  )

  return {
    isLoading,
    isError: fetchEntity1.isError || fetchEntity2.isError,
    isDeleted,
    isSuccess,
    data: [fetchEntity1.data, fetchEntity2.data] as P[],
    errors: [fetchEntity1.error, fetchEntity2.error].filter(excludeFalsy),
    twoEntityValues: values,
    clientsData,
  }
}
