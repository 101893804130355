import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

import { AppPermissions } from 'types/permissions/permissions'

export type PermissionsArg = AppPermissions | AppPermissions[]
const checkPermissions = (permissionsSet: Set<string>, requiredPermissions?: PermissionsArg, requireAll?: boolean) => {
  const permissionsToCheck = Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions]

  if (!permissionsSet.size) return false
  if (!permissionsToCheck.length) return true
  if (requireAll) return permissionsToCheck?.every(permission => permissionsSet.has(permission!))

  return permissionsToCheck?.some(permission => permissionsSet.has(permission!))
}

export const useIsPermitted = () => {
  const {
    osContext: { permissions },
  } = useOs()

  const permissionsSet = useMemo(() => {
    return permissions.reduce((set, record) => {
      record.permissions.forEach(permission => set.add(permission))
      return set
    }, new Set<string>())
  }, [permissions])

  const isPermitted = useCallback(
    (requiredPermissions?: PermissionsArg, requireAll?: boolean) => {
      return checkPermissions(permissionsSet, requiredPermissions, requireAll)
    },
    [permissionsSet],
  )

  return {
    isPermitted,
  }
}
