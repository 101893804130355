import { ConvertIndustryFormProps } from 'pages/industries/merge/form/types'
import { convertFormToFormDto } from 'pages/industries/merge/utils'

export const convertFormToPreview = ({ values, data }: ConvertIndustryFormProps) => {
  const formDTO = convertFormToFormDto({ values, data })

  const parent = data.find(entity => entity?.parent?.id === formDTO.parentId)?.parent

  return {
    ...formDTO,
    parent,
  }
}
