import { ConvertGeographyFormProps } from 'pages/geographies/merge/form/types'
import { convertFormToFormDto } from 'pages/geographies/merge/utils'

export const convertFormToPreview = ({ values, data }: ConvertGeographyFormProps) => {
  const entityLogo = data.find(entity => entity.logoThumbnail?.key === values.logoThumbnail)

  return {
    ...convertFormToFormDto({ values, data }),
    logoThumbnail: entityLogo?.logoThumbnail!,
    logoOriginal: entityLogo?.logoOriginal!,
  }
}
