import { WppTreeCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import {
  TreeChangeEventDetail,
  TreeType,
} from '@platform-ui-kit/components-library/dist/types/components/wpp-tree/types'
import { WppTree } from '@platform-ui-kit/components-library-react'
import { ComponentProps, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Hierarchy } from 'components/hierarchyBreadcrumbs/HierarchyBreadcrumbs'
import { useStableCallback } from 'hooks/useStableCallback'
import { routesManager } from 'utils/routesManager'

interface Props extends Omit<ComponentProps<typeof WppTree>, 'data'> {
  data?: Hierarchy[]
  navigationTo: keyof typeof routesManager.masterData
}

const deepChildren = (items: TreeType[]): TreeType[] => {
  if (items.length === 0) return items
  else return deepChildren(items[0].children!)
}

export const HierarchyTree = ({ data = [], navigationTo, ...rest }: Props) => {
  const navigate = useNavigate()
  const [closedIds, setClosedIds] = useState<string[]>([])

  const treeState = useMemo<TreeType[]>(
    () => [
      [...data].reverse().reduce((accum, { name: title, id }, index) => {
        const isLast = index === data?.length - 1
        const treeItem: TreeType = {
          id,
          title,
          open: !closedIds.includes(id),
          children: [],
          selected: isLast,
        }

        if (index === 0) return treeItem
        const innerChildren = deepChildren(accum.children!)
        innerChildren.push(treeItem)
        return accum
      }, {} as TreeType)!,
    ],
    [data, closedIds],
  )

  const onTreeClick = useStableCallback(({ detail }: WppTreeCustomEvent<TreeChangeEventDetail>) => {
    const entryId = detail.currentItem?.id as string
    if (detail.reason === 'open') {
      setClosedIds(state => (state.includes(entryId) ? state.filter(item => item !== entryId) : [...state, entryId]))
    } else {
      navigate(routesManager.masterData[navigationTo].update.getURL({ entryId }))
    }
  })

  return <WppTree data={treeState} withItemsTruncation onWppChange={onTreeClick} {...rest} />
}
