import { WppAvatar } from '@platform-ui-kit/components-library-react'
import { forwardRef, ComponentPropsWithoutRef, useMemo } from 'react'

type Props = ComponentPropsWithoutRef<typeof WppAvatar>

export const Avatar = forwardRef<HTMLWppAvatarElement, Props>(function Avatar({ name, ...rest }, ref) {
  const nameMapped = name || ''

  const color = useMemo(() => {
    const charCode = nameMapped.toLowerCase().charCodeAt(0)
    // Valid colors for avatars: theme.color.dataviz.cat.neutral-[1-9]
    const colorIndex = isNaN(charCode) ? 1 : (charCode % 9) + 1

    return `var(--wpp-dataviz-color-cat-neutral-${colorIndex})`
  }, [nameMapped])

  return <WppAvatar ref={ref} name={nameMapped} color={color} {...rest} />
})
