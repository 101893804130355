import { WppCheckbox } from '@platform-ui-kit/components-library-react'
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useRef,
} from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import { useField } from 'hooks/form/useField'
import { useStableCallback } from 'hooks/useStableCallback'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppCheckbox>, 'checked' | 'onChange' | 'onWppChange'> {
  name: string
  flexAlign?: ComponentProps<typeof Flex>['align']
  onAfterChange?: (arg: boolean) => void
}

export const CHECKBOX_INPUT_WIDTH = 45
export const LABEL_GAP = 8
export const FormCheckboxCustomLabel = forwardRef<HTMLWppCheckboxElement, PropsWithChildren<Props>>(
  ({ name, onWppBlur, children, onAfterChange, disabled, className, flexAlign = 'center', ...rest }, ref) => {
    const {
      field: { ref: fieldRef, value, onChange: onFieldChange, onBlur },
    } = useField({ name })

    const innerRef = useRef<HTMLWppCheckboxElement>(null)

    const handleChange = useStableCallback((value: boolean) => {
      onFieldChange(value)
      onAfterChange?.(value)
    })

    useImperativeHandle(
      fieldRef,
      () => ({
        focus: () => innerRef.current?.setFocus(),
      }),
      [],
    )

    return (
      <Flex align={flexAlign} gap={LABEL_GAP} onClick={() => !disabled && handleChange(!value)} className={className}>
        <WppCheckbox
          {...rest}
          ref={mergeRefs([innerRef, ref])}
          name={name}
          checked={value}
          disabled={disabled}
          onWppChange={event => handleChange(event.detail.checked)}
          onWppBlur={e => {
            onBlur()
            onWppBlur?.(e)
          }}
        />
        {children}
      </Flex>
    )
  },
)
