import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { UpsertEntityProvider } from 'pages/components/upsertEntityWrapper/components/UpsertEntityProvider'
import { ListCurrencies } from 'pages/currencies/list/ListCurrencies'
import { MergeCurrencies } from 'pages/currencies/merge/MergeCurrencies'
import { UpsertCurrency } from 'pages/currencies/upsert/UpsertCurrency'
import { routesManager } from 'utils/routesManager'

const WrappedUpsertCurrency = (
  <UpsertEntityProvider>
    <UpsertCurrency />
  </UpsertEntityProvider>
)

export const Currencies = () => (
  <Routes>
    <Route path={routesManager.masterData.currencies.root.relativePattern} index element={<ListCurrencies />} />
    <Route path={routesManager.masterData.currencies.merge.relativePattern} element={<MergeCurrencies />} />
    <Route path={routesManager.masterData.currencies.create.relativePattern} element={WrappedUpsertCurrency} />
    <Route path={routesManager.masterData.currencies.update.relativePattern} element={WrappedUpsertCurrency} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
