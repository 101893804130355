import { MergeCurrenciesForm } from 'pages/currencies/merge/form/types'

export const defaultFormValues: MergeCurrenciesForm = {
  name: '',
  isNewName: false,
  newName: '',
  aliases: [],
  isoAlpha3: null,
  numCode: null,
  minorUnit: null,
  withdrawalDate: null,
}
