interface URLParams {
  entryId: string
}

export interface URLMergeParams {
  entry1Id: string
  entry2Id: string
}

interface EntryRoute {
  pattern: string
  relativePattern: string
  getURL: (arg: URLParams) => string | (() => string)
}

interface MergeEntryRoute {
  pattern: string
  relativePattern: string
  getURL: (arg: URLMergeParams) => string | (() => string)
}

interface MasterDataRouter {
  root: EntryRoute
  create: EntryRoute
  update: EntryRoute
  merge?: MergeEntryRoute
}

export const routesManager = {
  home: {
    root: {
      pattern: '/',
      getURL: () => '/',
    },
  },
  masterData: {
    agencies: {
      root: {
        pattern: '/agencies/*',
        relativePattern: '/',
        getURL: () => '/agencies',
      },
      create: {
        pattern: '/agencies/create',
        relativePattern: '/create',
        getURL: () => '/agencies/create',
      },
      update: {
        pattern: '/agencies/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/agencies/${entryId}/update`,
      },
      merge: {
        pattern: '/agencies/merge/:entry1Id/:entry2Id',
        relativePattern: '/merge/:entry1Id/:entry2Id',
        getURL: ({ entry1Id, entry2Id }: URLMergeParams) => `/agencies/merge/${entry1Id}/${entry2Id}`,
      },
    },
    clients: {
      root: {
        pattern: '/clients/*',
        relativePattern: '/',
        getURL: () => '/clients',
      },
      create: {
        pattern: '/clients/create',
        relativePattern: '/create',
        getURL: () => '/clients/create',
      },
      update: {
        pattern: '/clients/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/clients/${entryId}/update`,
      },
      merge: {
        pattern: '/clients/merge/:entry1Id/:entry2Id',
        relativePattern: '/merge/:entry1Id/:entry2Id',
        getURL: ({ entry1Id, entry2Id }: URLMergeParams) => `/clients/merge/${entry1Id}/${entry2Id}`,
      },
    },
    brands: {
      root: {
        pattern: '/brands/*',
        relativePattern: '/',
        getURL: () => '/brands',
      },
      create: {
        pattern: '/brands/create',
        relativePattern: '/create',
        getURL: () => '/brands/create',
      },
      update: {
        pattern: '/brands/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/brands/${entryId}/update`,
      },
      merge: {
        pattern: '/brands/merge/:entry1Id/:entry2Id',
        relativePattern: '/merge/:entry1Id/:entry2Id',
        getURL: ({ entry1Id, entry2Id }: URLMergeParams) => `/brands/merge/${entry1Id}/${entry2Id}`,
      },
    },
    industries: {
      root: {
        pattern: '/industries/*',
        relativePattern: '/',
        getURL: () => '/industries',
      },
      create: {
        pattern: '/industries/create',
        relativePattern: '/create',
        getURL: () => '/industries/create',
      },
      update: {
        pattern: '/industries/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/industries/${entryId}/update`,
      },
      merge: {
        pattern: '/industries/merge/:entry1Id/:entry2Id',
        relativePattern: '/merge/:entry1Id/:entry2Id',
        getURL: ({ entry1Id, entry2Id }: URLMergeParams) => `/industries/merge/${entry1Id}/${entry2Id}`,
      },
    },
    markets: {
      root: {
        pattern: '/markets/*',
        relativePattern: '/',
        getURL: () => '/markets',
      },
      create: {
        pattern: '/markets/create',
        relativePattern: '/create',
        getURL: () => '/markets/create',
      },
      update: {
        pattern: '/markets/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/markets/${entryId}/update`,
      },
      merge: {
        pattern: '/markets/merge/:entry1Id/:entry2Id',
        relativePattern: '/merge/:entry1Id/:entry2Id',
        getURL: ({ entry1Id, entry2Id }: URLMergeParams) => `/markets/merge/${entry1Id}/${entry2Id}`,
      },
    },
    geographies: {
      root: {
        pattern: '/geographies/*',
        relativePattern: '/',
        getURL: () => '/geographies',
      },
      create: {
        pattern: '/geographies/create',
        relativePattern: '/create',
        getURL: () => '/geographies/create',
      },
      update: {
        pattern: '/geographies/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/geographies/${entryId}/update`,
      },
      merge: {
        pattern: '/geographies/merge/:entry1Id/:entry2Id',
        relativePattern: '/merge/:entry1Id/:entry2Id',
        getURL: ({ entry1Id, entry2Id }: URLMergeParams) => `/geographies/merge/${entry1Id}/${entry2Id}`,
      },
    },
    currencies: {
      root: {
        pattern: '/currencies/*',
        relativePattern: '/',
        getURL: () => '/currencies',
      },
      create: {
        pattern: '/currencies/create',
        relativePattern: '/create',
        getURL: () => '/currencies/create',
      },
      update: {
        pattern: '/currencies/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/currencies/${entryId}/update`,
      },
      merge: {
        pattern: '/currencies/merge/:entry1Id/:entry2Id',
        relativePattern: '/merge/:entry1Id/:entry2Id',
        getURL: ({ entry1Id, entry2Id }: URLMergeParams) => `/currencies/merge/${entry1Id}/${entry2Id}`,
      },
    },
  } satisfies Record<string, MasterDataRouter>,
  '404': {
    root: {
      pattern: '/404',
      getURL: () => '/404',
    },
  },
} as const
