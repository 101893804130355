import { MergeCurrenciesValues } from 'pages/currencies/merge/form/types'
import { Currency } from 'types/masterData/currencies'
import { preparePillsLike } from 'utils/merge/preparePillsLike'
import { prepareTextLike } from 'utils/merge/prepareTextLike'

export const convertToTwoEntityValues = ([entry1, entry2]: Currency[]): MergeCurrenciesValues => {
  const aliases = preparePillsLike({ entries: [entry1, entry2], field: 'aliases' })

  const isoAlpha3 = prepareTextLike({ entries: [entry1, entry2], field: 'isoAlpha3' })

  const numCode = prepareTextLike({ entries: [entry1, entry2], field: 'numCode' })

  const minorUnit = prepareTextLike({ entries: [entry1, entry2], field: 'minorUnit' })

  const withdrawalDate = prepareTextLike({ entries: [entry1, entry2], field: 'withdrawalDate' })

  const tableRowDataFields = [...aliases, ...isoAlpha3, ...numCode, ...minorUnit, ...withdrawalDate]

  return {
    fields: {
      name: [entry1.name, entry2.name],
    },
    tableRowDataFields,
  }
}
