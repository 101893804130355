import { AutocompleteOptionId } from '@platform-ui-kit/components-library'
import { ComponentProps } from 'react'
import { useFormContext } from 'react-hook-form'

import { Tooltip } from 'components/common/tooltip/Tooltip'
import { Truncate } from 'components/common/truncate/Truncate'
import {
  CHECKBOX_INPUT_WIDTH,
  FormCheckboxCustomLabel,
  LABEL_GAP,
} from 'components/form/formCheckboxCustomLabel/FormCheckboxCustomLabel'
import { getFormFieldNameAndIndex } from 'components/merge/table/components/utils'
import { useStableCallback } from 'hooks/useStableCallback'

interface Props extends ComponentProps<typeof FormCheckboxCustomLabel> {
  cellWidth?: number
  label?: string
  value?: AutocompleteOptionId
  isSameValue?: boolean
  formIndex?: 0 | 1
  name: string
}

export const TableCheckboxCell = ({ cellWidth = 0, value, label, name, isSameValue, formIndex, ...rest }: Props) => {
  const width = Math.max(cellWidth - CHECKBOX_INPUT_WIDTH - LABEL_GAP, 200)

  const { setValue } = useFormContext()

  const handleChangeLogic = useStableCallback((val: boolean) => {
    if (!isSameValue) return
    const { name: fieldName, index: fieldIndex } = getFormFieldNameAndIndex(name)
    const otherFieldIndex = formIndex === 0 ? 1 : 0
    setValue(`${fieldName}.${otherFieldIndex}.${fieldIndex}`, val)
  })

  return (
    <FormCheckboxCustomLabel value={value || label} name={name} required onAfterChange={handleChangeLogic} {...rest}>
      <Tooltip text={label} show showOnOverflow>
        <Truncate type="s-body" style={{ maxWidth: `${width}px` }}>
          {label}
        </Truncate>
      </Tooltip>
    </FormCheckboxCustomLabel>
  )
}
