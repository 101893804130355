import { MergeMarketsForm } from 'pages/markets/merge/form/types'

export const defaultFormValues: MergeMarketsForm = {
  name: '',
  type: null,
  isNewName: false,
  newName: '',
  isoAlpha2: '',
  isoAlpha3: '',
  geographyIds: [],
  currencyIds: [],
  logoThumbnail: null,
  aliases: [],
  parentId: null,
}
