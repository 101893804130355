import { convertFormValuesFieldToFormDTO } from 'components/merge/utils'
import { defaultFormValues } from 'pages/agencies/merge/form/defaultValues'
import { ConvertAgencyFormProps, MergeAgenciesForm } from 'pages/agencies/merge/form/types'
import { convertToTwoEntityValues } from 'pages/agencies/merge/twoEntities/utils'
import { Agency, AgencyFormDTO } from 'types/masterData/agencies'
import { arrayFieldDefaultValue } from 'utils/merge/utils'

export const convertToForm = ([entry1, entry2]: Agency[]): MergeAgenciesForm => {
  const logoThumbnail = entry1.logoThumbnail?.key || entry2.logoThumbnail?.key || null

  const description = entry1.description || entry2.description
  const shortDescription = entry1.shortDescription || entry2.shortDescription

  const parentId = entry1?.parent?.id || entry2?.parent?.id || null

  const serviceName = entry1.serviceName || entry2.serviceName
  const navigatorUrl = entry1.navigatorUrl || entry2.navigatorUrl

  return {
    ...defaultFormValues,
    displayName: entry1.displayName,
    logoThumbnail,
    description,
    shortDescription,
    aliases: arrayFieldDefaultValue(entry1.aliases.length, entry2.aliases.length),
    parentId,
    serviceName,
    navigatorUrl,
    domains: arrayFieldDefaultValue(entry1.domains.length, entry2.domains.length),
    clientIds: arrayFieldDefaultValue(entry1.clientIds.length, entry2.clientIds.length),
  }
}

export const convertFormToFormDto = ({ values, clientsData, data }: ConvertAgencyFormProps): AgencyFormDTO => {
  const formValues = convertToTwoEntityValues(data, clientsData)
  const displayName = values.isNewName ? values.newName : values.displayName

  const aliases = convertFormValuesFieldToFormDTO({
    formValues,
    values,
    field: 'aliases',
  })

  const clientIds = convertFormValuesFieldToFormDTO({
    formValues,
    values,
    valueGetterFn: item => item?.id?.toString(),
    field: 'clientIds',
  })

  const domains = convertFormValuesFieldToFormDTO({
    formValues,
    values,
    field: 'domains',
  })

  const entityLogo = data.find(entity => entity.logoThumbnail?.key === values.logoThumbnail)

  return {
    displayName,
    aliases,
    logoThumbnail: entityLogo?.logoThumbnail
      ? {
          name: entityLogo?.logoThumbnail?.name!,
          key: entityLogo?.logoThumbnail?.key!,
          size: entityLogo?.logoThumbnail?.size!,
        }
      : null,
    logoOriginal: entityLogo?.logoOriginal
      ? {
          name: entityLogo?.logoOriginal?.name!,
          key: entityLogo?.logoOriginal?.key!,
          size: entityLogo?.logoOriginal?.size!,
        }
      : null,
    shortDescription: values.shortDescription,
    description: values.description,
    serviceName: values.serviceName,
    navigatorUrl: values.navigatorUrl,
    parentId: values.parentId,
    domains,
    clientIds,
  }
}
