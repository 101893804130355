import { WppGrid } from '@platform-ui-kit/components-library-react'
import { ComponentProps, PropsWithChildren } from 'react'

interface Props extends ComponentProps<typeof WppGrid> {
  wrappedCardContent: boolean
  hasRightCards: boolean
}

export const UpsertEntityContentBlock = ({
  wrappedCardContent,
  hasRightCards,
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  if (!wrappedCardContent) return children

  return (
    <WppGrid fluid container {...rest}>
      <WppGrid item all={hasRightCards ? 24 : 16}>
        {children}
      </WppGrid>
    </WppGrid>
  )
}
