// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VKn1Y{position:absolute;inset:0;background:rgba(77,83,88,.6);border-radius:50%;opacity:0;transition:all .5s ease;transition-property:opacity,background-color}.VElaS{position:relative;flex-shrink:0;overflow:hidden;border-radius:50%;cursor:pointer}.VElaS:hover .VKn1Y,.VElaS:focus .VKn1Y{opacity:1}.VElaS.OGiMU{cursor:not-allowed;opacity:.8}.VElaS.OGiMU .VKn1Y{opacity:0}.SL65C{opacity:.65}._D1tt{color:var(--wpp-white-color)}`, "",{"version":3,"sources":["webpack://./src/components/common/avatar/avatarCropEdition/AvatarCropEdition.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,OAAA,CACA,4BAAA,CACA,iBAAA,CACA,SAAA,CACA,uBAAA,CACA,4CAAA,CAGF,OACE,iBAAA,CACA,aAAA,CACA,eAAA,CACA,iBAAA,CACA,cAAA,CAIE,wCACE,SAAA,CAIJ,aACE,kBAAA,CACA,UAAA,CAEA,oBACE,SAAA,CAKN,OACE,WAAA,CAGF,OACE,4BAAA","sourcesContent":[".overlay {\n  position: absolute;\n  inset: 0;\n  background: rgb(77 83 88 / 60%);\n  border-radius: 50%;\n  opacity: 0;\n  transition: all 0.5s ease;\n  transition-property: opacity, background-color;\n}\n\n.root {\n  position: relative;\n  flex-shrink: 0;\n  overflow: hidden;\n  border-radius: 50%;\n  cursor: pointer;\n\n  &:hover,\n  &:focus {\n    .overlay {\n      opacity: 1;\n    }\n  }\n\n  &.disabled {\n    cursor: not-allowed;\n    opacity: 0.8;\n\n    .overlay {\n      opacity: 0;\n    }\n  }\n}\n\n.isEmptyValue {\n  opacity: 0.65;\n}\n\n.icon {\n  color: var(--wpp-white-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `VKn1Y`,
	"root": `VElaS`,
	"disabled": `OGiMU`,
	"isEmptyValue": `SL65C`,
	"icon": `_D1tt`
};
export default ___CSS_LOADER_EXPORT___;
