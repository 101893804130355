import { useAsyncFn } from 'react-use'

import { useFetchAgenciesApi } from 'api/masterData/queries/useFetchAgenciesApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'
import { Agency } from 'types/masterData/agencies'
import { excludeFalsy } from 'utils/common'

interface Params {
  search?: string
}

export const handleReloadAgenciesListTable = async (entryId?: string) => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_AGENCIES] }),
    queryClient.invalidateQueries({
      queryKey: [ApiQueryKeys.MASTER_DATA_AGENCY, entryId && { entryId }].filter(excludeFalsy),
    }),
  ])
  tableActions.reload(TableKey.MASTER_DATA_AGENCIES)
}

export const useAgenciesLoader = ({ search }: Params) => {
  const handleFetchAgenciesList = useFetchAgenciesApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const [{ loading: isLoading, value }, loader] = useAsyncFn<TableInfiniteLoader<Agency>>(
    async ({ startRow, endRow, sortModel }) => {
      const size = endRow - startRow
      const [{ colId, sort }] = sortModel

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchAgenciesList({
        itemsPerPage: size,
        sort: sort === 'asc' ? colId : `-${colId}`,
        search,
        page: endRow / size,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [search, handleFetchAgenciesList],
  )

  return { isLoading, loader, hasResults: !!value?.totalRowsCount }
}
