import { MayBeNull } from '@wpp-open/core'
import { AxiosResponse } from 'axios'

import { createUseQuery } from 'api/common/createUseQuery'
import { Unpacked } from 'types/common/utils'
import { EntityFormLikeAllFields } from 'types/masterData/utils'

export type CancelableRequestProducer<T> = (signal?: AbortSignal) => Promise<AxiosResponse<T>>

export class ForcedApiError extends Error {}

export type QueryFetcher<T extends unknown = unknown, P extends Record<string, any> = any> = (
  params: P,
) => CancelableRequestProducer<T>

export type QueryFetcherParams<F extends QueryFetcher> = Parameters<F>[0]
export type QueryFetcherResponse<F extends QueryFetcher> = Unpacked<ReturnType<ReturnType<F>>>

export type PaginationParams<T extends Record<string, any>> = T & {
  page?: number
  itemsPerPage?: number
  search?: string
}

export type UseQueryReturnType<F extends EntityFormLikeAllFields = EntityFormLikeAllFields> = ReturnType<
  ReturnType<typeof createUseQuery<QueryFetcher<(arg: F) => F>, F>>
>

export interface PaginatedResponse<T> {
  data: T[]
  navigator: {
    firstPage: string
    lastPage: MayBeNull<string>
    nextPage: MayBeNull<string>
    previousPage: MayBeNull<string>
  }
  paginator: {
    itemsPerPage: number
    page: number
    totalItems: number
    totalPages: number
  }
}

export type SortOrder = 'asc' | 'desc'
