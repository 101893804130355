import { Entity } from 'components/common/readonlyPills/ReadonlyPills'
import { ConvertAgencyFormProps } from 'pages/agencies/merge/form/types'
import { convertFormToFormDto } from 'pages/agencies/merge/utils'
import { uniqBy } from 'utils/common'

export const convertFormToPreview = ({ values, clientsData, data }: ConvertAgencyFormProps) => {
  const formDTO = convertFormToFormDto({ values, data, clientsData })

  const entityLogo = data.find(entity => entity.logoThumbnail?.key === values.logoThumbnail)
  const parent = data.find(entity => entity?.parent?.id === formDTO.parentId)?.parent

  const clients = uniqBy(
    formDTO.clientIds.map(clientId => {
      const client = clientsData.find(client => client.id === clientId)
      return {
        id: client?.id,
        name: client?.label,
      } as Entity
    }),
    entity => entity?.id!,
  )

  return {
    ...formDTO,
    logoThumbnail: entityLogo?.logoThumbnail!,
    logoOriginal: entityLogo?.logoOriginal!,
    parent,
    clients,
  }
}
