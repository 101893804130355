import { MergeIndustriesValues } from 'pages/industries/merge/form/types'
import { Industry } from 'types/masterData/industries'
import { prepareParentId } from 'utils/merge/prepareParentId'
import { preparePillsLike } from 'utils/merge/preparePillsLike'

export const convertToTwoEntityValues = ([entry1, entry2]: Industry[]): MergeIndustriesValues => {
  const aliases = preparePillsLike({ entries: [entry1, entry2], field: 'aliases' })

  const parentId = prepareParentId<Industry>({
    entries: [entry1, entry2],
    extractFn: entry =>
      entry.parent
        ? {
            id: entry.parent?.id!,
            label: entry.parent?.name!,
          }
        : null,
  })

  const tableRowDataFields = [...aliases, ...parentId]

  return {
    fields: {
      name: [entry1.name, entry2.name],
    },
    tableRowDataFields,
  }
}
