import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'
import { WppButton } from '@platform-ui-kit/components-library-react'
import { FieldErrors, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useMergeAgenciesApi } from 'api/masterData/mutations/useMergeAgenciesApi'
import { MergeAgenciesForm } from 'pages/agencies/merge/form/types'
import { convertFormToFormDto } from 'pages/agencies/merge/utils'
import { handleReloadAgenciesListTable } from 'pages/agencies/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Agency } from 'types/masterData/agencies'
import { routesManager } from 'utils/routesManager'

interface Props {
  form: UseFormReturn<MergeAgenciesForm>
  data: Agency[]
  clientsData: AutocompleteDefaultOption[]
}

export const ActionButtons = ({ form, data, clientsData }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleMerge } = useMergeAgenciesApi()
  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = form

  const onMergeValidationFail = (errors: FieldErrors<MergeAgenciesForm>) => {
    if (errors.serviceName) {
      return enqueueToast({
        message: t('master_data.merge_entities.validation.agency_service_name_error'),
      })
    }
  }

  const onMergeSubmit = async (values: MergeAgenciesForm) => {
    try {
      const mainEntity = data.find(entity => entity.displayName === values.displayName)
      const secondaryEntity = data.find(entity => entity.displayName !== values.displayName)

      await handleMerge({
        mainId: mainEntity?.id!,
        secondaryId: secondaryEntity?.id!,
        agency: convertFormToFormDto({ values, data, clientsData }),
      })
      enqueueToast({
        message: t('master_data.merge_entities.notification.success', {
          entity1: mainEntity?.displayName,
          entity2: secondaryEntity?.displayName,
        }),
        type: 'success',
      })

      await handleReloadAgenciesListTable()
      navigate(routesManager.masterData.agencies.update.getURL({ entryId: mainEntity?.id! }))
    } catch {
      enqueueToast({
        message: t('common.errors.general'),
        type: 'error',
      })
    }
  }

  return (
    <>
      <WppButton variant="secondary" disabled={!isDirty} onClick={() => reset()}>
        {t('common.reset')}
      </WppButton>
      <WppButton
        loading={isSubmitting}
        variant="primary"
        onClick={() => handleSubmit(onMergeSubmit, onMergeValidationFail)()}
      >
        {t('master_data.merge_entities.action_button')}
      </WppButton>
    </>
  )
}
