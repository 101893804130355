import { SideModalFormConfig } from '@platform-ui-kit/components-library'
import { WppSideModal } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, ComponentProps } from 'react'

import { Portal } from 'components/common/portal/Portal'

export type SideModalProps = Omit<ComponentPropsWithoutRef<typeof WppSideModal>, 'formConfig'> & {
  /**
   * If you pass this prop wrapper of dialog will be rendered as form.
   */
  formConfig?: Pick<ComponentProps<'form'>, 'onSubmit' | 'onReset'>
}

export const SideModal = forwardRef<HTMLWppSideModalElement, SideModalProps>(({ formConfig, ...rest }, ref) => (
  <Portal>
    <WppSideModal ref={ref} {...rest} formConfig={formConfig as SideModalFormConfig} />
  </Portal>
))
