import { WppButton, WppIconReject, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/importEntitiesModal/ImportEntitiesModal.module.scss'
import { ErrorCodes, ImportError, parseDetail, unknown_field_error } from 'components/importEntitiesModal/utils'

interface Props {
  onClose: () => void
  handleReset: () => void
  errors: ImportError[][]
}

export const ImportEntitiesErrorBody = ({ onClose, handleReset, errors }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <WppTypography slot="header" type="xl-heading">
        {t('import.modal.title_error')}
      </WppTypography>

      <Flex gap={12} direction="column" slot="body">
        <WppTypography type="s-body">{t('import.modal.subtitle_error')}</WppTypography>
        <Flex gap={12} className={styles.errorsBody} direction="column">
          {errors.map(error =>
            error[0].line ? (
              <Flex gap={8} key={error[0].line}>
                <Flex align="start" className={styles.rowNumberLabel} gap={4}>
                  <WppIconReject color="var(--wpp-danger-color-400)" />
                  <WppTypography type="s-body">{t('import.errors.row', { number: error[0].line })}</WppTypography>
                </Flex>
                <Flex className={styles.errorBody} direction="column">
                  {error.map(errorDetail => (
                    <WppTypography type="s-body" key={errorDetail.type}>
                      {t(`import.errors.${ErrorCodes[errorDetail.type] || unknown_field_error}`)}
                      {parseDetail(errorDetail.detail, t)}
                    </WppTypography>
                  ))}
                </Flex>
              </Flex>
            ) : (
              error.map(errorDetail => (
                <Flex align="start" gap={4} key={errorDetail.type}>
                  <WppIconReject color="var(--wpp-danger-color-400)" />
                  <WppTypography type="s-body">
                    {t(`import.errors.${ErrorCodes[errorDetail.type] || unknown_field_error}`)}
                    {parseDetail(errorDetail.detail, t)}
                  </WppTypography>
                </Flex>
              ))
            ),
          )}
        </Flex>
        <WppTypography type="s-body">{t('import.modal.help_message_error')}</WppTypography>
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={handleReset} variant="secondary" size="s" data-testid="error-reupload">
          {t('import.modal.reupload')}
        </WppButton>
        <WppButton type="button" size="s" variant="primary" data-testid="error-cancel" onClick={onClose}>
          {t('import.modal.cancel')}
        </WppButton>
      </Flex>
    </>
  )
}
