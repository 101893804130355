import * as zod from 'zod'

export const zodEmptyString = zod.literal('')

export const autocompleteOptionType = zod.object({
  id: zod.string(),
  label: zod.string(),
})

export const fileType = zod.object({
  key: zod.string(),
  name: zod.string(),
  size: zod.number(),
})
