import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { useOs } from '@wpp-open/react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'

import { AppRoutes } from 'app/routes/AppRoutes'
import { Layout } from 'components/surface/layout/Layout'

export function App() {
  const { osContext } = useOs()

  const router = createBrowserRouter(createRoutesFromElements(AppRoutes), {
    basename: osContext.baseUrl !== '/' ? `/${osContext.baseUrl}` : osContext.baseUrl,
  })

  return (
    <NiceModalProvider>
      <Layout>
        <RouterProvider router={router} />
      </Layout>
    </NiceModalProvider>
  )
}
