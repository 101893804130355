import { MergeBrandsForm } from 'pages/brands/merge/form/types'

export const defaultFormValues: MergeBrandsForm = {
  name: '',
  isNewName: false,
  newName: '',
  logoThumbnail: null,
  aliases: [],
  clientId: null,
  industryIds: [],
  parentId: null,
  children: [],
}
