import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { defaultFormValues } from 'pages/markets/merge/form/defaultValues'
import { nameFieldCharacterMaxCount, nameFieldCharacterMinCount } from 'pages/markets/upsert/utils'
import { MarketType } from 'types/masterData/markets'

export const useMarketsMergeValidationScheme = () => {
  const { t } = useTranslation()

  const nameSchema = zod
    .string()
    .trim()
    .min(
      nameFieldCharacterMinCount,
      t('master_data.validation.name.min', {
        entity: t('common.master_data.entities.market'),
        characterCount: nameFieldCharacterMinCount,
      }),
    )
    .max(
      nameFieldCharacterMaxCount,
      t('master_data.validation.name.max', {
        entity: t('common.master_data.entities.market'),
        characterCount: nameFieldCharacterMaxCount,
      }),
    )

  const schema = zod.object({
    ...Object.fromEntries(Object.keys(defaultFormValues).map(field => [field, zod.any()])),
    isNewName: zod.boolean(),
    type: zod.string(),
    newName: zod.string().optional(),
    isoAlpha2: zod.string().nullable().optional(),
    isoAlpha3: zod.string().nullable().optional(),
    parentId: zod.string().nullable().optional(),
  })

  return useMemo(
    () =>
      schema.superRefine((data, ctx) => {
        if (data.isNewName) {
          const result = nameSchema.safeParse(data.newName)
          if (!result.success) {
            result.error.errors.forEach(error =>
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                path: ['newName'],
                message: error.message,
              }),
            )
          }
        }

        if (data.type === MarketType.COUNTRY) {
          if (!data.isoAlpha2) {
            ctx.addIssue({
              code: zod.ZodIssueCode.custom,
              path: ['isoAlpha2'],
              message: t('common.errors.general'),
            })
          }
          if (!data.isoAlpha3) {
            ctx.addIssue({
              code: zod.ZodIssueCode.custom,
              path: ['isoAlpha3'],
              message: t('common.errors.general'),
            })
          }
        } else if (data.type === MarketType.CITY || data.type === MarketType.STATE) {
          if (!data.parentId) {
            ctx.addIssue({
              code: zod.ZodIssueCode.custom,
              path: ['parentId'],
              message: t('common.errors.general'),
            })
          }
        }
      }),
    [t, nameSchema, schema],
  )
}
