import { facadeApi } from 'api'
import { getApiModulePath } from 'api/common/utils'
import { EntityAllFields, RouteModules } from 'types/masterData/utils'

interface Params {
  module: RouteModules
  versionId: string
  entryId: string
}

export const fetchVersionedEntityApi = ({ entryId, versionId, module }: Params) =>
  facadeApi.get<EntityAllFields>(`/${getApiModulePath(module)}/${entryId}/versions/${versionId}`)
