import qs from 'qs'

import { facadeApi } from 'api'
import { PaginationParams, SortOrder } from 'api/common/types'
import { MarketsListSortBy } from 'api/masterData/fetchers/fetchMarketsList'
import { MarketType } from 'types/masterData/markets'

export type Params = PaginationParams<{
  search?: string
  sortBy?: MarketsListSortBy
  orderBy?: SortOrder
  filterType?: MarketType
  regionId?: string[]
}>

export const fetchMarketsExportApi = ({ filterType, regionId, search, page, itemsPerPage, sortBy, orderBy }: Params) =>
  facadeApi.get<Blob>('/markets/export', {
    params: {
      page,
      itemsPerPage,
      sortBy,
      orderBy,
      filter: {
        ...(!!search && { search }),
        ...(!!filterType && { type: filterType }),
        ...(!!regionId && { regionId }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
    responseType: 'blob',
  })
