import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormAgenciesAutocomplete } from 'components/form/formAutocomplete/FormAgenciesAutocomplete'
import { FormInputAutocomplete } from 'components/form/formAutocomplete/FormInputAutocomplete'
import { FormReadonlyPills } from 'components/form/formReadonlyPills/FormReadonlyPills'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import {
  descriptionFieldCharacterMaxCount,
  descriptionFieldCharacterWarningThreshold,
} from 'pages/agencies/upsert/utils'
import { useUpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { Agency } from 'types/masterData/agencies'

interface Props extends ComponentProps<typeof Flex> {
  agency: Agency
  isLoading: boolean
}

export const UpsertDetailsTab = ({ agency, isLoading, ...rest }: Props) => {
  const { t } = useTranslation()
  const {
    contextState: { selectedVersionFields, nonCurrentVersionSelected },
  } = useUpsertEntityContext()

  const shouldDisableFields = nonCurrentVersionSelected || isLoading

  return (
    <Flex direction="column" gap={24} {...rest}>
      <ManageMasterDataLogoUpload
        name={agency?.displayName || ''}
        disabled={shouldDisableFields}
        tagMessage={selectedVersionFields.logoOriginal}
        logoOriginalUrl={agency?.logoOriginal?.url || null}
        logoThumbnailUrl={agency?.logoThumbnail?.url || null}
      />
      <FormTextInput
        tagMessage={selectedVersionFields.displayName}
        disabled={shouldDisableFields}
        name="displayName"
        labelConfig={{
          text: t('master_data.fields.name.label'),
          description: t('master_data.fields.name.hint'),
        }}
        required
      />
      <FormInputAutocomplete
        tagMessage={selectedVersionFields.aliases}
        disabled={shouldDisableFields}
        labelConfig={{
          text: t('master_data.fields.aliases.label'),
          description: t('master_data.fields.aliases.hint'),
        }}
        name="aliases"
      />
      <FormTextInput
        disabled={shouldDisableFields}
        tagMessage={selectedVersionFields.shortDescription}
        name="shortDescription"
        labelConfig={{
          text: t('master_data.fields.short_description.label'),
        }}
      />
      <FormTextareaInput
        name="description"
        disabled={shouldDisableFields}
        tagMessage={selectedVersionFields.description}
        charactersLimit={descriptionFieldCharacterMaxCount}
        warningThreshold={descriptionFieldCharacterWarningThreshold}
        labelConfig={{
          text: t('master_data.fields.description.label'),
        }}
      />
      <FormAgenciesAutocomplete
        tagMessage={selectedVersionFields.parentId}
        disabled={shouldDisableFields}
        name="parentId"
        multiple={false}
        labelConfig={{
          text: t('master_data.fields.parent.label', { entity: t('common.master_data.lowercase_entities.agency') }),
          description: t('master_data.fields.parent.hint', {
            entity: t('common.master_data.lowercase_entities.agency'),
            entities: t('common.master_data.lowercase_entities.agency', { count: 2 }),
          }),
        }}
      />
      <FormReadonlyPills
        tagMessage={selectedVersionFields.children}
        disabled={shouldDisableFields}
        navigationTo="agencies"
        labelConfig={{
          text: t('master_data.fields.subsidiaries.label'),
          description: t('master_data.fields.subsidiaries.hint', {
            entity: t('common.master_data.entities.agency'),
            entities: t('common.master_data.entities.agency', { count: 2 }),
          }),
        }}
        name="children"
        getItemLabel={item => item.displayName}
      />
      <FormTextInput
        tagMessage={selectedVersionFields.serviceName}
        disabled={shouldDisableFields}
        name="serviceName"
        labelConfig={{
          text: t('master_data.fields.service_name.label'),
        }}
        required
      />
      <FormTextInput
        tagMessage={selectedVersionFields.navigatorUrl}
        disabled={shouldDisableFields}
        name="navigatorUrl"
        labelConfig={{
          text: t('master_data.fields.navigator_url.label'),
          description: t('master_data.fields.navigator_url.hint'),
        }}
      />

      <FormInputAutocomplete
        tagMessage={selectedVersionFields.domains}
        disabled={shouldDisableFields}
        labelConfig={{
          text: t('master_data.fields.domains.label'),
          description: t('master_data.fields.domains.hint'),
        }}
        dropdownConfig={{
          placement: 'top',
          popperOptions: {
            strategy: 'fixed',
          },
        }}
        name="domains"
      />
    </Flex>
  )
}
