import { WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import styles from 'components/breadcrumbs/Breadcrumbs.module.scss'
import { useIsFormDirty } from 'components/breadcrumbs/hooks/useIsFormDirty'
import { Flex } from 'components/common/flex/Flex'
import { showUnsavedConfirmationModal } from 'components/unsavedConfirmationModal/UnsavedConfirmationModal'
import { MarketTypeDefaultValue } from 'types/masterData/markets'
import { LocationState } from 'types/masterData/state'
import { EntityFormLikeAllFields, RouteModules } from 'types/masterData/utils'
import { mapEntityNames } from 'utils/entity'
import { routesManager } from 'utils/routesManager'

interface Props extends ComponentProps<typeof Flex> {
  entity?: EntityFormLikeAllFields
}

export const Breadcrumbs = ({ entity, ...rest }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const isDirty = useIsFormDirty()

  const locationState: LocationState = state

  const moduleName = pathname?.split('/')?.[1] as RouteModules
  const entityType = entity?.type || locationState?.filters?.type
  const searchTerm = locationState?.filters?.search

  const navigateSearchParams = createSearchParams({
    ...(entityType &&
      entityType !== MarketTypeDefaultValue && {
        type: entityType,
      }),
    ...(!!searchTerm && { search: searchTerm }),
  }).toString()

  const handleNavigate = () =>
    navigate({
      pathname: routesManager.masterData[moduleName].root.getURL(),
      search: navigateSearchParams,
    })

  const handleBreadcrumbsClick = () => {
    if (!isDirty) {
      return handleNavigate()
    }
    showUnsavedConfirmationModal({
      onSubmit: handleNavigate,
    })
  }

  return (
    <Flex
      onClick={handleBreadcrumbsClick}
      className={styles.backButton}
      align="center"
      gap={4}
      data-testid="breadcrumbs-back-button"
      {...rest}
    >
      <WppIconChevron direction="left" />
      <WppTypography className={styles.buttonLabel} type="s-body">
        {t('navigation.back_to_entity', {
          entity: t(`common.master_data.lowercase_entities.${mapEntityNames[moduleName]}`, {
            count: 2,
          }),
        })}
      </WppTypography>
    </Flex>
  )
}
