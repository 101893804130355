import { MergeableEntity } from 'types/masterData/mergeEntities'
import { EntityAllFields, EntityFormAllFields } from 'types/masterData/utils'

export const prepareLogo = <T extends EntityAllFields>([entry1, entry2]: T[]) => {
  if (!entry1.logoThumbnail && !entry2.logoThumbnail) return []

  return [
    {
      id: 'logoThumbnail',
      index: 0,
      entry1: {
        data: entry1.logoThumbnail!,
        isSameValue: false,
        entryName: entry1?.name! || entry1?.displayName!,
      },
      entry2: {
        data: entry2.logoThumbnail!,
        isSameValue: false,
        entryName: entry2?.name! || entry2?.displayName!,
      },
    } as const satisfies MergeableEntity<EntityFormAllFields>,
  ]
}
