import { initReactI18next } from 'react-i18next'

import { defaultTaxonomy } from 'i18n/defaultTaxonomy'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import TRANSLATIONS_EN from 'locales/en/translation.json'
import { isDevelop } from 'utils/common'

const resources = {
  en: {
    translation: TRANSLATIONS_EN,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    debug: isDevelop,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      defaultVariables: defaultTaxonomy,
    },
  })

export default i18n
