import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { EntityPills } from 'pages/components/mergeEntityWrapper/components/EntityPills'
import { EntityTextPreview } from 'pages/components/mergeEntityWrapper/components/EntityTextPreview'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { MergeCurrenciesForm } from 'pages/currencies/merge/form/types'
import { convertFormToPreview } from 'pages/currencies/merge/preview-utils'
import { Currency } from 'types/masterData/currencies'
import { MergeCardProps } from 'types/masterData/mergeEntities'

export const PreviewResult = ({ data, ...rest }: MergeCardProps) => {
  const { t } = useTranslation()
  const { watch } = useFormContext<MergeCurrenciesForm>()
  const formValues = watch()

  const preview = convertFormToPreview({ values: formValues, data: data as Currency[] })

  return (
    <WppCard {...rest}>
      <WppTypography type="l-strong" slot="header">
        {t('master_data.merge_entities.titles.preview', { order: 3 })}
      </WppTypography>
      <WppTypography type="m-body" className={styles.descriptionText}>
        {t('master_data.merge_entities.descriptions.preview')}
      </WppTypography>
      <Flex direction="column" gap={24}>
        <EntityTextPreview
          label={t('master_data.merge_entities.fields.entity_name', {
            entity: t('common.master_data.entities.currency'),
          })}
          text={preview.name!}
          hasEmptyState
        />

        <EntityPills data={preview.aliases!} label={t('master_data.merge_entities.fields.aliases')} />

        <EntityTextPreview label={t('master_data.merge_entities.fields.isoAlpha3')} text={preview.isoAlpha3} />

        <EntityTextPreview label={t('master_data.merge_entities.fields.numCode')} text={preview.numCode} />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.minorUnit')}
          text={t(`master_data.currencies.minor_unit.${preview.minorUnit?.toLocaleLowerCase()}`)}
        />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.withdrawalDate')}
          text={preview.withdrawalDate}
        />
      </Flex>
    </WppCard>
  )
}
