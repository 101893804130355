// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gBeMG,.nmA2O{overflow:hidden;overflow:clip;text-overflow:ellipsis;word-break:break-all}.gBeMG{display:block;white-space:nowrap}.nmA2O::part(typography){display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:var(--lines)}`, "",{"version":3,"sources":["webpack://./src/components/common/truncate/Truncate.module.scss"],"names":[],"mappings":"AAAA,cAEE,eAAA,CACA,aAAA,CACA,sBAAA,CACA,oBAAA,CAGF,OACE,aAAA,CACA,kBAAA,CAIA,yBACE,mBAAA,CACA,2BAAA,CACA,+BAAA","sourcesContent":[".single,\n.multiple {\n  overflow: hidden;\n  overflow: clip;\n  text-overflow: ellipsis;\n  word-break: break-all;\n}\n\n.single {\n  display: block;\n  white-space: nowrap;\n}\n\n.multiple {\n  &::part(typography) {\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    -webkit-line-clamp: var(--lines);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"single": `gBeMG`,
	"multiple": `nmA2O`
};
export default ___CSS_LOADER_EXPORT___;
