import { FullscreenAppCustomProps } from '@wpp-open/core'
import { OsProvider } from '@wpp-open/react'

import { App } from 'app/App'
import 'i18n/i18n'
import { ApiProvider } from 'providers/api/ApiProvider'
import { OsQueryClientProvider } from 'providers/osQueryClient/OsQueryClientProvider'
import { ToastProvider } from 'providers/toast/ToastProvider'

export const Root = (props: FullscreenAppCustomProps) => {
  return (
    <OsQueryClientProvider>
      <OsProvider {...props}>
        <ToastProvider>
          <ApiProvider>
            <App />
          </ApiProvider>
        </ToastProvider>
      </OsProvider>
    </OsQueryClientProvider>
  )
}
