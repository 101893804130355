import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useFetchGeographyApi } from 'api/masterData/queries/useFetchGeographyApi'
import { useForm } from 'hooks/form/useForm'
import { QueryFn, useFetchTwoEntities } from 'hooks/masterData/useFetchTwoEntities'
import { MergeEntityWrapper } from 'pages/components/mergeEntityWrapper/MergeEntityWrapper'
import { ActionButtons } from 'pages/geographies/merge/components/ActionButtons'
import { CompareEntities } from 'pages/geographies/merge/components/CompareEntities'
import { PreviewResult } from 'pages/geographies/merge/components/PreviewResult'
import { SelectMainEntity } from 'pages/geographies/merge/components/SelectMainEntity'
import { defaultFormValues } from 'pages/geographies/merge/form/defaultValues'
import { useGeographiesMergeValidationScheme } from 'pages/geographies/merge/form/formValidation'
import { MergeGeographiesValues } from 'pages/geographies/merge/form/types'
import { defaultTwoEntityValues } from 'pages/geographies/merge/twoEntities/defaultValues'
import { convertToTwoEntityValues } from 'pages/geographies/merge/twoEntities/utils'
import { convertToForm } from 'pages/geographies/merge/utils'
import { Geography } from 'types/masterData/geographies'

export const MergeGeographies = () => {
  const { t } = useTranslation()
  const fetchGeographies = useFetchTwoEntities<Geography, MergeGeographiesValues>({
    queryFn: useFetchGeographyApi as QueryFn<Geography>,
    getValuesFn: ({ isSuccess, data }) => (isSuccess ? convertToTwoEntityValues(data) : defaultTwoEntityValues),
  })
  const { data, isSuccess } = fetchGeographies

  const form = useForm({
    defaultValues: isSuccess ? convertToForm(data) : defaultFormValues,
    validationSchema: useGeographiesMergeValidationScheme(),
  })

  return (
    <FormProvider {...form}>
      <MergeEntityWrapper
        title={t('master_data.merge_entities.merge_title', {
          entity: t('common.master_data.lowercase_entities.geography_other'),
        })}
        actionButtons={<ActionButtons data={data} form={form} />}
        fetchEntities={fetchGeographies}
        selectMainEntity={SelectMainEntity}
        previewResult={PreviewResult}
        compareEntities={CompareEntities}
      />
    </FormProvider>
  )
}
