import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { fetchMarketsList } from 'api/masterData/fetchers/fetchMarketsList'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteMarketsApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.MASTER_DATA_MARKETS_INFINITE,
  fetcher: fetchMarketsList,
  selector: res => res?.pages?.flatMap(page => page.data?.data) || [],
  getNextPageParam: ({
    data: {
      paginator: { page, totalPages },
    },
  }) => (page < totalPages ? { page: page + 1 } : undefined),
})
