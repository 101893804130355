import qs from 'qs'

import { facadeApi } from 'api'
import { PaginationParams, SortOrder } from 'api/common/types'
import { GeographiesListSortBy } from 'api/masterData/fetchers/fetchGeographiesList'

export type Params = PaginationParams<{
  search?: string
  sortBy?: GeographiesListSortBy
  orderBy?: SortOrder
}>

export const fetchGeographiesExportApi = ({ search, page, itemsPerPage, sortBy, orderBy }: Params) =>
  facadeApi.get<Blob>('/geographies/export', {
    params: {
      page,
      itemsPerPage,
      sortBy,
      orderBy,
      filter: {
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
    responseType: 'blob',
  })
