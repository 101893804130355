import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { fetchEntityChangeHistoryApi } from 'api/masterData/fetchers/fetchEntityChangeHistoryApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { mapChangeHistoryEntityItems } from 'pages/components/entityChangeHistory/utils'

export const useInfiniteEntityChangeHistoryApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.MASTER_DATA_ENTITY_CHANGE_HISTORY,
  fetcher: fetchEntityChangeHistoryApi,
  selector: (res, isCollapsed) => {
    const firstPage = res?.pages?.[0]?.data?.data || []
    const allPages = res?.pages?.flatMap(page => page.data?.data) || []
    const items = isCollapsed ? firstPage : allPages

    return mapChangeHistoryEntityItems(
      items.map((item, index) => ({
        ...item,
        isCurrentVersion: index === 0,
      })),
    )
  },
  getNextPageParam: ({
    data: {
      paginator: { page, totalPages },
    },
  }) => (page < totalPages ? { page: page + 1 } : undefined),
})
