import { WppTag } from '@platform-ui-kit/components-library-react'
import { ComponentProps, PropsWithChildren } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/form/formWrapTag/FormWrapTag.module.scss'

interface Props extends ComponentProps<typeof Flex> {
  tagMessage?: string
}

export const FormWrapTag = ({ children, tagMessage, ...rest }: PropsWithChildren<Props>) => {
  if (!tagMessage) return children
  return (
    <Flex className={styles.body} {...rest}>
      {children}
      <WppTag label={tagMessage} categoricalColorIndex={1} className={styles.tag} />
    </Flex>
  )
}
