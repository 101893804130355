import { convertFormValuesFieldToFormDTO } from 'components/merge/utils'
import { defaultFormValues } from 'pages/geographies/merge/form/defaultValues'
import { ConvertGeographyFormProps, MergeGeographiesForm } from 'pages/geographies/merge/form/types'
import { convertToTwoEntityValues } from 'pages/geographies/merge/twoEntities/utils'
import { Geography, GeographyFormDTO } from 'types/masterData/geographies'
import { arrayFieldDefaultValue } from 'utils/merge/utils'

export const convertToForm = ([geography1, geography2]: Geography[]): MergeGeographiesForm => {
  const logoThumbnail = geography1.logoThumbnail?.key || geography2.logoThumbnail?.key || null

  return {
    ...defaultFormValues,
    name: geography1.name,
    aliases: arrayFieldDefaultValue(geography1.aliases.length, geography2.aliases.length),
    logoThumbnail,
  }
}

export const convertFormToFormDto = ({ values, data }: ConvertGeographyFormProps): GeographyFormDTO => {
  const formValues = convertToTwoEntityValues(data)
  const name = values.isNewName ? values.newName : values.name

  const aliases = convertFormValuesFieldToFormDTO({
    formValues,
    values,
    field: 'aliases',
  })

  const entityLogo = data.find(entity => entity.logoThumbnail?.key === values.logoThumbnail)

  return {
    name,
    aliases,
    logoThumbnail: entityLogo?.logoThumbnail
      ? {
          name: entityLogo?.logoThumbnail?.name!,
          key: entityLogo?.logoThumbnail?.key!,
          size: entityLogo?.logoThumbnail?.size!,
        }
      : null,
    logoOriginal: entityLogo?.logoOriginal
      ? {
          name: entityLogo?.logoOriginal?.name!,
          key: entityLogo?.logoOriginal?.key!,
          size: entityLogo?.logoOriginal?.size!,
        }
      : null,
  }
}
