import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'

import { MergeAgenciesValues } from 'pages/agencies/merge/form/types'
import { Agency } from 'types/masterData/agencies'
import { excludeFalsy } from 'utils/common'
import { prepareIdBasedPills } from 'utils/merge/prepareIdBasedPills'
import { prepareLogo } from 'utils/merge/prepareLogo'
import { prepareParentId } from 'utils/merge/prepareParentId'
import { preparePillsLike } from 'utils/merge/preparePillsLike'
import { prepareTextLike } from 'utils/merge/prepareTextLike'

export const convertToTwoEntityValues = (
  [entry1, entry2]: Agency[],
  clientsData: AutocompleteDefaultOption[],
): MergeAgenciesValues => {
  const logo = prepareLogo([entry1, entry2])

  const aliases = preparePillsLike({ entries: [entry1, entry2], field: 'aliases' })

  const shortDescription = prepareTextLike({ entries: [entry1, entry2], field: 'shortDescription' })

  const description = prepareTextLike({ entries: [entry1, entry2], field: 'description' })

  const parentId = prepareParentId<Agency>({
    entries: [entry1, entry2],
    extractFn: entry =>
      entry.parent
        ? {
            id: entry.parent?.id!,
            label: entry.parent?.displayName!,
          }
        : null,
  })

  const serviceName = prepareTextLike({ entries: [entry1, entry2], field: 'serviceName' })

  const navigatorUrl = prepareTextLike({ entries: [entry1, entry2], field: 'navigatorUrl' })

  const domains = preparePillsLike({ entries: [entry1, entry2], field: 'domains' })

  const clientIds = prepareIdBasedPills({
    entries: [entry1, entry2],
    extractFn: entry => entry.clientIds?.map(id => clientsData?.find(client => client.id === id)!).filter(excludeFalsy),
    field: 'clientIds',
  })

  const tableRowDataFields = [
    ...logo,
    ...aliases,
    ...shortDescription,
    ...description,
    ...parentId,
    ...serviceName,
    ...navigatorUrl,
    ...domains,
    ...clientIds,
  ]

  return {
    fields: {
      displayName: [entry1.displayName, entry2.displayName],
    },
    tableRowDataFields,
  }
}
