import { WppTypography, WppActionButton } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/404/NotFound.module.scss'
import { routesManager } from 'utils/routesManager'

export const NotFound = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const mainModulePage = useMemo(() => {
    const allModules = Object.keys(routesManager.masterData)
    const module = pathname.split('/')?.[1]
    const moduleExists = allModules.includes(module)
    if (!moduleExists) return '/'
    return `/${module}`
  }, [pathname])

  const { t } = useTranslation()

  return (
    <Flex direction="column" gap={24} justify="center" align="center" className={styles.container}>
      <WppTypography type="2xl-heading">{t('error_pages.not_found.sub_title')}</WppTypography>
      <WppActionButton onClick={() => navigate(mainModulePage)}>
        {t('error_pages.not_found.primary_button')}
      </WppActionButton>
    </Flex>
  )
}
