import {
  WppActionButton,
  WppIconEdit,
  WppIconMore,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
} from '@platform-ui-kit/components-library-react'
import { RowClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'

import { AgenciesListSortBy } from 'api/masterData/fetchers/fetchAgenciesList'
import { AvatarWithNameCell } from 'components/cellRenderers/avatarWithName/AvatarWithNameCell'
import { AvatarWithInfoSkeleton } from 'components/cellRenderers/skeletons/avatarWithInfoSkeleton/AvatarWithInfoSkeleton'
import { ColDef, tableActions, TableInfinite } from 'components/common/table'
import { useOnSelectionChanged } from 'components/common/table/hooks/useOnRowSelected'
import { HierarchyBreadcrumbs } from 'components/hierarchyBreadcrumbs/HierarchyBreadcrumbs'
import { TableKey } from 'constants/table'
import { useDateFormat } from 'hooks/useDateFormat'
import { useStableCallback } from 'hooks/useStableCallback'
import { showDeleteAgencyModal } from 'pages/agencies/deleteAgencyModal/DeleteAgencyModal'
import { useAgenciesLoader } from 'pages/agencies/utils'
import styles from 'pages/components/tablePageWrapper/TablePageWrapper.module.scss'
import { useTableNoRowsOverlay } from 'pages/hooks/useTableNoRowsOverlay'
import { Agency } from 'types/masterData/agencies'
import { LocationState } from 'types/masterData/state'
import { CommonFilterState, MergeFilterState } from 'types/masterData/utils'
import { hasClosestInteractiveElement } from 'utils/dom'
import { routesManager } from 'utils/routesManager'

interface Props {
  stateObj: ReturnType<typeof useSetState<CommonFilterState<MergeFilterState>>>
}

export const TableListAgencies = ({ stateObj }: Props) => {
  const [{ search, isEmpty, selectedRows }, setState] = stateObj
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formatDate } = useDateFormat()

  const columnDefs = useMemo<ColDef<Agency>[]>(
    () => [
      {
        width: 40,
        colId: 'select',
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        onCellClicked: ({ node }) => {
          node.setSelected(!node.isSelected(), false, 'checkboxSelected')
        },
      },
      {
        flex: 1,
        colId: AgenciesListSortBy.displayName,
        loadingCellRenderer: AvatarWithInfoSkeleton,
        sortable: true,
        sort: 'asc',
        cellRenderer: ({ data }) => {
          const { displayName, logoThumbnail } = data!
          return <AvatarWithNameCell avatarThumbnail={logoThumbnail?.url!} userName={displayName || ''} />
        },
        headerName: t('tables.agencies.columns.name'),
      },
      {
        flex: 0.75,
        colId: AgenciesListSortBy.aliases,
        headerName: t('tables.agencies.columns.aliases'),
        valueGetter: ({ data }) => data!.aliases.join(', ') ?? null,
        tooltipValueGetter: ({ data }) => data!.aliases.join(', ') ?? '-',
      },
      {
        flex: 1,
        colId: AgenciesListSortBy.parents,
        headerName: t('tables.agencies.columns.parents'),
        cellRenderer: ({ data, column }) => (
          <HierarchyBreadcrumbs
            navigationTo="agencies"
            width={column?.getActualWidth()}
            currentItem={data}
            hierarchy={data?.hierarchy}
          />
        ),
      },
      {
        flex: 0.5,
        colId: AgenciesListSortBy.updatedAt,
        sortable: true,
        valueGetter: ({ data }) => formatDate(data?.updatedAt!) || formatDate(data?.createdAt!),
        headerName: t('tables.agencies.columns.updated'),
      },
      {
        width: 60,
        colId: 'actions',
        cellRenderer: ({ data }) => {
          return (
            <WppMenuContext
              className={styles.moreMenu}
              dropdownConfig={{
                appendTo: () => document.body,
                placement: 'bottom-end',
              }}
            >
              <WppActionButton slot="trigger-element">
                <WppIconMore direction="horizontal" />
              </WppActionButton>

              <WppListItem
                onWppChangeListItem={() =>
                  navigate(routesManager.masterData.agencies.update.getURL({ entryId: data!.id }), {
                    state: { data },
                  })
                }
              >
                <WppIconEdit slot="left" />
                <span slot="label">{t('common.edit')}</span>
              </WppListItem>

              <WppListItem onWppChangeListItem={() => showDeleteAgencyModal({ agency: data! })}>
                <WppIconTrash slot="left" />
                <span slot="label">{t('common.remove')}</span>
              </WppListItem>
            </WppMenuContext>
          )
        },
      },
    ],
    [t, navigate, formatDate],
  )

  const { loader } = useAgenciesLoader({ search })

  const onSelectionChanged = useOnSelectionChanged<Agency>({ setState })

  const noRowsOverlayComponent = useTableNoRowsOverlay({ isEmptySearch: isEmpty, search })

  const handleOnRowClicked = useStableCallback(async ({ event, data }: RowClickedEvent<Agency>) => {
    const target = event?.target as HTMLElement

    if (data && !hasClosestInteractiveElement(target, ['[role="gridcell"][col-id="select"]'])) {
      navigate(routesManager.masterData.agencies.update.getURL({ entryId: data.id }), {
        state: { data, filters: { search } } satisfies LocationState,
      })
    }
  })

  return (
    <TableInfinite
      tableKey={TableKey.MASTER_DATA_AGENCIES}
      className={clsx('agencies-table', styles.table)}
      columnDefs={columnDefs}
      noRowsOverlayComponent={noRowsOverlayComponent}
      onSelectionChanged={onSelectionChanged}
      onRowClicked={handleOnRowClicked}
      loader={loader}
      getRowId={params => params.data.id}
      onLoadSuccess={({ isEmptySource }) => {
        setState({ isEmpty: isEmptySource, isEmptySearch: isEmptySource && !!search })
        tableActions.selectRows(TableKey.MASTER_DATA_AGENCIES, selectedRows)
      }}
    />
  )
}
