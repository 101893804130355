import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'

import { useFetchCurrenciesApi } from 'api/masterData/queries/useFetchCurrenciesApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'
import { Currency } from 'types/masterData/currencies'
import { excludeFalsy } from 'utils/common'

interface Params {
  search?: string
}

export const handleReloadCurrenciesListTable = async (entryId?: string) => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_CURRENCIES] }),
    queryClient.invalidateQueries({
      queryKey: [ApiQueryKeys.MASTER_DATA_CURRENCY, entryId && { entryId }].filter(excludeFalsy),
    }),
  ])
  tableActions.reload(TableKey.MASTER_DATA_CURRENCIES)
}

export const minotUnitEmptyValue = 'EMPTY'
export const minorUnitValues = [minotUnitEmptyValue, 'ZERO', 'ONE', 'TWO', 'THREE', 'FOUR'] as const

export const useGetMinorUnitOptions = () => {
  const { t } = useTranslation()
  return minorUnitValues.map(item => ({
    id: item,
    value: item,
    label: t(`master_data.currencies.minor_unit.${item.toLocaleLowerCase()}`),
  }))
}

export const useCurrenciesLoader = ({ search }: Params) => {
  const handleFetchCurrenciesList = useFetchCurrenciesApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const [{ loading: isLoading, value }, loader] = useAsyncFn<TableInfiniteLoader<Currency>>(
    async ({ startRow, endRow, sortModel }) => {
      const size = endRow - startRow
      const [{ colId, sort }] = sortModel

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchCurrenciesList({
        itemsPerPage: size,
        sort: sort === 'asc' ? colId : `-${colId}`,
        search,
        page: endRow / size,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [search, handleFetchCurrenciesList],
  )

  return { isLoading, loader, hasResults: !!value?.totalRowsCount }
}
