import { WppRadio } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useImperativeHandle, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { useField } from 'hooks/form/useField'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppRadio>, 'checked' | 'onChange' | 'onWppChange'> {
  name: string
  label?: string
}

export const FormRadio = forwardRef<HTMLWppRadioElement, Props>(
  ({ name, label, onWppBlur, labelConfig, labelTooltipConfig, ...rest }, ref) => {
    const {
      field: { ref: fieldRef, value, onChange, onBlur },
    } = useField({ name })

    const innerRef = useRef<HTMLWppRadioElement>(null)

    useImperativeHandle(
      fieldRef,
      () => ({
        focus: () => innerRef.current?.setFocus(),
      }),
      [],
    )

    return (
      <WppRadio
        {...rest}
        ref={mergeRefs([innerRef, ref])}
        name={name}
        checked={value === rest.value}
        labelConfig={{
          text: label!,
        }}
        onWppChange={event => {
          onChange(event.detail.value)
        }}
        onWppBlur={e => {
          onBlur()
          onWppBlur?.(e)
        }}
      />
    )
  },
)
