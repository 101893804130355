// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n90pi::part(typography){color:var(--wpp-grey-color-800)}.RWgjZ{cursor:default}.RWgjZ::part(textarea){cursor:default}`, "",{"version":3,"sources":["webpack://./src/pages/components/entityDetailsCards/EntityDetailsCards.module.scss"],"names":[],"mappings":"AACE,yBACE,+BAAA,CAIJ,OACE,cAAA,CAEA,uBACE,cAAA","sourcesContent":[".detail {\n  &::part(typography) {\n    color: var(--wpp-grey-color-800);\n  }\n}\n\n.disabledField {\n  cursor: default;\n\n  &::part(textarea) {\n    cursor: default;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detail": `n90pi`,
	"disabledField": `RWgjZ`
};
export default ___CSS_LOADER_EXPORT___;
