import { ConvertCurrencyFormProps } from 'pages/currencies/merge/form/types'
import { convertFormToFormDto } from 'pages/currencies/merge/utils'

export const convertFormToPreview = ({ values, data }: ConvertCurrencyFormProps) => {
  const formDTO = convertFormToFormDto({ values, data })

  return {
    ...formDTO,
  }
}
