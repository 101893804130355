// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VlmKI{max-height:30vh;overflow:hidden auto;text-overflow:ellipsis}.fWbB8 ::part(typography){white-space:nowrap}.Uo471{flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/components/importEntitiesModal/ImportEntitiesModal.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,oBAAA,CACA,sBAAA,CAIA,0BACE,kBAAA,CAIJ,OACE,WAAA","sourcesContent":[".errorsBody {\n  max-height: 30vh;\n  overflow: hidden auto;\n  text-overflow: ellipsis;\n}\n\n.rowNumberLabel {\n  ::part(typography) {\n    white-space: nowrap;\n  }\n}\n\n.errorBody {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorsBody": `VlmKI`,
	"rowNumberLabel": `fWbB8`,
	"errorBody": `Uo471`
};
export default ___CSS_LOADER_EXPORT___;
