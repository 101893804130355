import { MergeAgenciesForm } from 'pages/agencies/merge/form/types'

export const defaultFormValues: MergeAgenciesForm = {
  displayName: '',
  isNewName: false,
  newName: '',
  logoThumbnail: null,
  aliases: [],
  description: null,
  shortDescription: null,
  parentId: null,
  serviceName: '',
  navigatorUrl: null,
  domains: [],
  clientIds: [],
}
