import { useTranslation } from 'react-i18next'

import { MarketType } from 'types/masterData/markets'
import { EntityAllFields } from 'types/masterData/utils'

interface Props {
  data?: EntityAllFields[]
}

export const useGetParentIdName = ({ data }: Props) => {
  const { t } = useTranslation()
  const marketType = data?.[0]?.type || data?.[1]?.type || MarketType.STATE

  const isState = marketType === MarketType.STATE
  return t(`master_data.merge_entities.fields.${isState ? 'market_country' : 'market_country_or_state'}`)
}
