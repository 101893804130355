import { SelectTypes } from '@platform-ui-kit/components-library'
import { useRef, forwardRef, Ref, useImperativeHandle } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Select, SelectProps } from 'components/common/select/Select'
import { FormWrapTag } from 'components/form/formWrapTag/FormWrapTag'
import { useField } from 'hooks/form/useField'

export interface FormSelectProps<T extends Record<string, any>> extends Omit<SelectProps<T>, 'onChange' | 'value'> {
  name: string
  type?: SelectTypes
  search?: string
  isFixedPositioningStrategy?: boolean
  'data-testid'?: string
}

export const FormSelect = forwardRef(function FormSelect<T extends Record<string, any>>(
  {
    name,
    message,
    messageType,
    'data-testid': dataTestId,
    type = 'single',
    search,
    onWppChange,
    dropdownConfig,
    tagMessage,
    ...rest
  }: FormSelectProps<T>,
  ref: Ref<HTMLWppSelectElement>,
) {
  const {
    field: { ref: fieldRef, value, onChange, onBlur },
    fieldState: { isTouched, error },
  } = useField({
    name,
  })

  const innerRef = useRef<HTMLWppSelectElement>(null)
  const isFocused = useRef(false) // TODO: temporary workaround to prevent double focus which causes a blink

  useImperativeHandle(
    fieldRef,
    () => ({
      focus: () => {
        if (!isFocused.current) {
          isFocused.current = true
          innerRef.current?.setFocus()
        }
      },
    }),
    [],
  )

  const errorText = error?.message
  const shouldShowError = isTouched && !!errorText

  return (
    <FormWrapTag tagMessage={tagMessage}>
      <Select
        ref={mergeRefs([innerRef, ref])}
        {...rest}
        name={name}
        type={type}
        value={value}
        search={search}
        dropdownConfig={{
          ...dropdownConfig,
          onHidden: instance => {
            isFocused.current = false
            dropdownConfig?.onHidden?.(instance)
            onBlur()
          },
        }}
        messageType={shouldShowError ? 'error' : messageType}
        message={shouldShowError ? errorText : message}
        onWppChange={e => {
          onChange(e.detail.value)
          onWppChange?.(e)
        }}
        data-testid={dataTestId}
      />
    </FormWrapTag>
  )
}) as <T extends Record<string, any>>(props: SelectProps<T>) => JSX.Element
