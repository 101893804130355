import { ConvertBrandFormProps } from 'pages/brands/merge/form/types'
import { convertFormToFormDto } from 'pages/brands/merge/utils'
import { uniqBy } from 'utils/common'

export const convertFormToPreview = ({ values, data }: ConvertBrandFormProps) => {
  const formDTO = convertFormToFormDto({ values, data })

  const entityLogo = data.find(entity => entity.logoThumbnail?.key === values.logoThumbnail)

  const children = uniqBy(
    data.flatMap(entity => entity.children),
    entity => entity.id,
  )

  const parent = data.find(entity => entity?.parent?.id === formDTO.parentId)?.parent

  const client = data.find(entity => entity?.client?.id === formDTO.clientId)?.client

  const industries = uniqBy(
    data.flatMap(entity => entity.industries.filter(industry => formDTO.industryIds.includes(industry.id))),
    entity => entity.id,
  )

  return {
    ...formDTO,
    logoThumbnail: entityLogo?.logoThumbnail!,
    logoOriginal: entityLogo?.logoOriginal!,
    children,
    industries,
    parent,
    client,
  }
}
