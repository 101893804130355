import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { ImportEntityForm, MAX_FILE_SIZE } from 'components/importEntitiesModal/utils'
import { routesManager } from 'utils/routesManager'

interface Props {
  onClose: () => void
  form: UseFormReturn<ImportEntityForm>
  moduleName: keyof (typeof routesManager)['masterData']
}

export const ImportEntitiesBody = ({ onClose, form, moduleName }: Props) => {
  const { t } = useTranslation()
  const {
    formState: { isValid, isSubmitting },
  } = form

  return (
    <>
      <WppTypography slot="header" type="xl-heading">
        {t('import.modal.title', { module: t(`tables.${moduleName}.title`) })}
      </WppTypography>

      <Flex gap={12} direction="column" slot="body">
        <WppTypography type="s-body">{t('import.modal.description')}</WppTypography>
        <FormFileUpload
          name="file"
          size={MAX_FILE_SIZE}
          multiple={false}
          acceptConfig={{
            'text/csv': ['.csv'],
          }}
        />
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} disabled={isSubmitting} variant="secondary" size="s" data-testid="cancel">
          {t('import.modal.cancel')}
        </WppButton>
        <WppButton
          type="submit"
          size="s"
          loading={isSubmitting}
          disabled={!isValid}
          variant="primary"
          data-testid="apply"
        >
          {t('import.modal.submit')}
        </WppButton>
      </Flex>
    </>
  )
}
