import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { UseQueryReturnType } from 'api/common/types'
import { useFetchCurrencyApi } from 'api/masterData/queries/useFetchCurrencyApi'
import { Flex } from 'components/common/flex/Flex'
import { FormInputAutocomplete } from 'components/form/formAutocomplete/FormInputAutocomplete'
import { FormReadonlyPills } from 'components/form/formReadonlyPills/FormReadonlyPills'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { useForm } from 'hooks/form/useForm'
import { EntityDetailsCards } from 'pages/components/entityDetailsCards/EntityDetailsCards'
import { useUpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { ActionButtons } from 'pages/currencies/upsert/components/ActionButtons'
import { convertToForm, defaultValues, useCurrencyValidationScheme } from 'pages/currencies/upsert/utils'
import { useGetMinorUnitOptions } from 'pages/currencies/utils'
import { Currency } from 'types/masterData/currencies'

export const UpsertCurrency = () => {
  const { t } = useTranslation()
  const { entryId } = useParams()
  const { state } = useLocation()
  const currencyState = state?.data as MayBeNull<Currency>

  const query = useFetchCurrencyApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })

  const currency = query.data || currencyState

  const form = useForm({
    defaultValues: currency ? convertToForm(currency) : defaultValues,
    validationSchema: useCurrencyValidationScheme(),
  })

  const {
    contextState: { selectedVersionFields, nonCurrentVersionSelected },
  } = useUpsertEntityContext()

  const shouldDisableFields = nonCurrentVersionSelected || query.isLoading
  const minorUnitOptions = useGetMinorUnitOptions()

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        query={query as UseQueryReturnType}
        title={t(`common.${currency ? 'update' : 'create'}_entity`, {
          entity: t('common.master_data.entities.currency'),
        })}
        actionButtons={<ActionButtons currency={currency!} form={form} />}
        rightCards={currency && <EntityDetailsCards entity={currency} />}
      >
        <Flex direction="column" gap={24}>
          <FormTextInput
            tagMessage={selectedVersionFields.name}
            disabled={shouldDisableFields}
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
            data-testid="currency-name"
          />
          <FormInputAutocomplete
            disabled={shouldDisableFields}
            tagMessage={selectedVersionFields.aliases}
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />
          <FormTextInput
            tagMessage={selectedVersionFields.isoAlpha3}
            disabled={shouldDisableFields}
            name="isoAlpha3"
            labelConfig={{
              text: t('master_data.fields.iso_alpha_3_currency.label'),
              description: t('master_data.fields.iso_alpha_3_currency.hint'),
            }}
            required
            data-testid="currency-iso-alpha3"
          />

          <FormTextInput
            tagMessage={selectedVersionFields.numCode}
            disabled={shouldDisableFields}
            name="numCode"
            labelConfig={{
              text: t('master_data.fields.num_code_currency.label'),
              description: t('master_data.fields.num_code_currency.hint'),
            }}
            required
            data-testid="currency-num-code"
          />
          <FormSelect
            tagMessage={selectedVersionFields.minorUnit}
            disabled={shouldDisableFields}
            name="minorUnit"
            labelConfig={{
              text: t('master_data.fields.minor_unit_currency.label'),
              description: t('master_data.fields.minor_unit_currency.hint'),
            }}
            options={minorUnitOptions}
            isInsideCard
          />
          <FormTextInput
            tagMessage={selectedVersionFields.withdrawalDate}
            disabled={shouldDisableFields}
            name="withdrawalDate"
            labelConfig={{
              text: t('master_data.fields.withdrawal_date.label'),
              description: t('master_data.fields.withdrawal_date.hint'),
            }}
            data-testid="currency-withdrawal-date"
          />

          <FormReadonlyPills
            disabled={shouldDisableFields}
            tagMessage={selectedVersionFields.markets}
            navigationTo="markets"
            labelConfig={{
              text: t('master_data.fields.currency_countries.label'),
            }}
            name="markets"
            getItemLabel={item => item.name}
          />
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}
