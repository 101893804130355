// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yJ3M4{width:100%}.tWlei{width:100%}.tWlei::part(item){width:100%}.Mrkxx{margin-top:8px}.ytGL3{display:none}`, "",{"version":3,"sources":["webpack://./src/pages/components/entityChangeHistory/EntityChangeHistory.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CAGF,OACE,UAAA,CAEA,mBACE,UAAA,CAIJ,OACE,cAAA,CAGF,OACE,YAAA","sourcesContent":[".body {\n  width: 100%;\n}\n\n.listItem {\n  width: 100%;\n\n  &::part(item) {\n    width: 100%;\n  }\n}\n\n.actionButtons {\n  margin-top: 8px;\n}\n\n.hidden {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `yJ3M4`,
	"listItem": `tWlei`,
	"actionButtons": `Mrkxx`,
	"hidden": `ytGL3`
};
export default ___CSS_LOADER_EXPORT___;
