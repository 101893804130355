import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { UpsertEntityProvider } from 'pages/components/upsertEntityWrapper/components/UpsertEntityProvider'
import { ListMarkets } from 'pages/markets/list/ListMarkets'
import { MergeMarkets } from 'pages/markets/merge/MergeMarkets'
import { UpsertMarket } from 'pages/markets/upsert/UpsertMarket'
import { routesManager } from 'utils/routesManager'

const WrappedUpsertMarket = (
  <UpsertEntityProvider>
    <UpsertMarket />
  </UpsertEntityProvider>
)

export const Markets = () => (
  <Routes>
    <Route path={routesManager.masterData.markets.root.relativePattern} index element={<ListMarkets />} />
    <Route path={routesManager.masterData.markets.merge.relativePattern} element={<MergeMarkets />} />
    <Route path={routesManager.masterData.markets.create.relativePattern} element={WrappedUpsertMarket} />
    <Route path={routesManager.masterData.markets.update.relativePattern} element={WrappedUpsertMarket} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
