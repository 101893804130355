import { WppLabel, WppPill, WppPillGroup } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'

import { Flex } from 'components/common/flex/Flex'

interface Props extends ComponentProps<typeof WppPillGroup> {
  data: string[]
  label: string
}

export const EntityPills = ({ data, label, ...rest }: Props) => {
  if (!data || data.length === 0) return null
  return (
    <Flex gap={8} direction="column">
      <WppLabel config={{ text: label }} />
      <WppPillGroup type="display" {...rest}>
        <Flex wrap="wrap" gap={6}>
          {data.map(item => (
            <WppPill removable={false} value={item} maxLength={42} label={item} key={item} data-testid="entity-pill" />
          ))}
        </Flex>
      </WppPillGroup>
    </Flex>
  )
}
