import { WppTooltip } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useRef, ComponentProps, ReactElement, useCallback } from 'react'

import styles from 'components/common/tooltip/Tooltip.module.scss'

interface Props extends Omit<ComponentProps<typeof WppTooltip>, 'text' | 'theme'> {
  show: boolean
  showOnOverflow?: boolean
  getInnerElement?: (el: MayBeNull<HTMLElement>) => MayBeNull<HTMLElement> | undefined
  text?: string
  children: ReactElement
}

export const Tooltip = ({
  className,
  show,
  showOnOverflow,
  getInnerElement,
  text,
  config,
  children,
  ...rest
}: Props) => {
  const childRef = useRef<HTMLDivElement>(null!)

  const onShow = useCallback(() => {
    if (!showOnOverflow) return // we don't have showOnOverflow prop, so we show tooltip without conditions
    const firstChild = childRef.current?.firstChild as HTMLElement
    const innerElement = getInnerElement?.(firstChild) || firstChild
    const hasOverflow = innerElement?.offsetWidth < innerElement?.scrollWidth

    const hasMultilineOverflow = innerElement?.scrollHeight > innerElement?.clientHeight
    if (!hasOverflow && !hasMultilineOverflow) {
      return false // do not show tooltip
    }
  }, [showOnOverflow, getInnerElement])

  if (!show || !text) return children as ReactElement

  return (
    <WppTooltip
      className={clsx(styles.tooltip, className)}
      config={{
        onShow,
        placement: 'top',
        appendTo: () => document.body,
        delay: 200,
        ...config,
      }}
      text={text}
      {...rest}
    >
      {showOnOverflow ? (
        <div className={styles.childWrap} ref={childRef}>
          {children}
        </div>
      ) : (
        children
      )}
    </WppTooltip>
  )
}
