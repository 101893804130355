import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { ListAgencies } from 'pages/agencies/list/ListAgencies'
import { MergeAgencies } from 'pages/agencies/merge/MergeAgencies'
import { UpsertAgency } from 'pages/agencies/upsert/UpsertAgency'
import { UpsertEntityProvider } from 'pages/components/upsertEntityWrapper/components/UpsertEntityProvider'
import { routesManager } from 'utils/routesManager'

const WrappedUpsertAgency = (
  <UpsertEntityProvider>
    <UpsertAgency />
  </UpsertEntityProvider>
)

export const Agencies = () => (
  <Routes>
    <Route path={routesManager.masterData.agencies.root.relativePattern} index element={<ListAgencies />} />
    <Route path={routesManager.masterData.agencies.merge.relativePattern} element={<MergeAgencies />} />
    <Route path={routesManager.masterData.agencies.create.relativePattern} element={WrappedUpsertAgency} />
    <Route path={routesManager.masterData.agencies.update.relativePattern} element={WrappedUpsertAgency} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
