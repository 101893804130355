import { WppButton, WppIconMerge, WppPill } from '@platform-ui-kit/components-library-react'
import { ComponentProps, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSetState } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { MAX_SELECTED_ROWS } from 'components/common/table/hooks/useOnRowSelected'
import { TableKey } from 'constants/table'
import { useStableCallback } from 'hooks/useStableCallback'
import { MarketType } from 'types/masterData/markets'
import { LocationState } from 'types/masterData/state'
import { CommonFilterState, MergeFilterState, RouteModules } from 'types/masterData/utils'
import { routesManager } from 'utils/routesManager'

interface Props extends ComponentProps<typeof Flex> {
  stateObj: ReturnType<typeof useSetState<CommonFilterState<MergeFilterState>>>
  tableKey: TableKey
  module: RouteModules
}

export const HeaderActionsList = ({ stateObj, tableKey, module, children, ...rest }: PropsWithChildren<Props>) => {
  const [{ selectedRows }, setState] = stateObj
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleRemoveSelected = useStableCallback(() => {
    setState({ selectedRows: [] })
    tableActions.deselectAll(tableKey)
  })

  const searchFilter = searchParams.get('search')
  const typeFilter = searchParams.get('type') as MarketType

  const handleClickMerge = useStableCallback(() =>
    navigate(
      routesManager.masterData[module].merge.getURL({
        entry1Id: selectedRows[0],
        entry2Id: selectedRows[1],
      }),
      {
        state: {
          filters: {
            ...(searchFilter && { search: searchFilter }),
            ...(typeFilter && { type: typeFilter }),
          },
        } satisfies LocationState,
      },
    ),
  )

  const hasSelectedRows = selectedRows.length > 0

  return (
    <Flex gap={8} align="center" {...rest}>
      {hasSelectedRows && (
        <>
          <WppPill removable type="display" onWppClose={handleRemoveSelected}>
            {t('master_data.merge_entities.selected', { count: selectedRows.length })}
          </WppPill>
          {selectedRows.length === MAX_SELECTED_ROWS && (
            <WppButton onClick={handleClickMerge} variant="secondary" size="s">
              <WppIconMerge slot="icon-start" />
              {t('master_data.merge_entities.action_button')}
            </WppButton>
          )}
        </>
      )}
      {children}
    </Flex>
  )
}
