import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { ColDef } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_ROW_HEIGHT, EXTENDED_ROW_HEIGHT, Table } from 'components/common/table'
import { renderCompareColumn } from 'components/merge/table/utils/RenderCompareColumn'
import { AgencyTableRowField, MergeAgenciesValues } from 'pages/agencies/merge/form/types'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { useTableNoMergeRowsOverlay } from 'pages/hooks/useTableNoMergeRowsOverlay'
import { ENTRY_NAME, MergeCardProps } from 'types/masterData/mergeEntities'

export const CompareEntities = ({ twoEntityValues, ...rest }: MergeCardProps<MergeAgenciesValues>) => {
  const { t } = useTranslation()
  const names = twoEntityValues.fields.displayName
  const firstEntityName = names[0]!
  const secondEntityName = names[1]!
  const tableNoMergeRowsOverlay = useTableNoMergeRowsOverlay()

  const columnDefs = useMemo<ColDef<AgencyTableRowField>[]>(
    () => [
      {
        flex: 0.8,
        headerName: t('master_data.merge_entities.table.columns.parameter'),
        cellRenderer: ({ data }: { data: AgencyTableRowField }) => {
          if (data.index !== 0) return null
          return (
            <WppTypography type="s-body">
              {t(`master_data.merge_entities.fields.${data.id}`, {
                entity: t('common.master_data.lowercase_entities.agency'),
              })}
            </WppTypography>
          )
        },
      },
      {
        flex: 1,
        headerName: firstEntityName,
        cellRenderer: renderCompareColumn({
          columnId: ENTRY_NAME[0],
          formIndex: 0,
        }),
      },
      {
        flex: 1,
        headerName: secondEntityName,
        cellRenderer: renderCompareColumn({
          columnId: ENTRY_NAME[1],
          formIndex: 1,
        }),
      },
    ],
    [firstEntityName, secondEntityName, t],
  )

  return (
    <WppCard {...rest}>
      <WppTypography type="l-strong" slot="header">
        {t('master_data.merge_entities.titles.select_values', { order: 2 })}
      </WppTypography>
      <WppTypography type="m-body" className={styles.descriptionText}>
        {t('master_data.merge_entities.descriptions.select_values_agencies')}
      </WppTypography>

      <Table
        noRowsOverlayComponent={tableNoMergeRowsOverlay}
        domLayout={twoEntityValues.tableRowDataFields.length === 0 ? 'normal' : 'autoHeight'}
        columnDefs={columnDefs}
        rowData={twoEntityValues.tableRowDataFields}
        getRowId={params => `${params.data.id}-${params.data.index}`}
        getRowHeight={params => (params.data?.id === 'logoThumbnail' ? EXTENDED_ROW_HEIGHT : DEFAULT_ROW_HEIGHT)}
        className={clsx({
          [styles.emptyTable]: twoEntityValues.tableRowDataFields.length === 0,
        })}
      />
    </WppCard>
  )
}
