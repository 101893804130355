import { MergeGeographiesValues } from 'pages/geographies/merge/form/types'
import { Geography } from 'types/masterData/geographies'
import { prepareLogo } from 'utils/merge/prepareLogo'
import { preparePillsLike } from 'utils/merge/preparePillsLike'

export const convertToTwoEntityValues = ([entry1, entry2]: Geography[]): MergeGeographiesValues => {
  const aliases = preparePillsLike({ entries: [entry1, entry2], field: 'aliases' })
  const logo = prepareLogo([entry1, entry2])

  const tableRowDataFields = [...logo, ...aliases]

  return {
    fields: {
      name: [entry1.name, entry2.name],
    },
    tableRowDataFields,
  }
}
