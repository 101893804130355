import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'
import { WppCard } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { AxiosError } from 'axios'
import { ComponentProps } from 'react'

import { ForcedApiError } from 'api/common/types'
import { UnwrapArray } from 'types/common/utils'
import { EntityAllFields, EntityFormAllFields } from 'types/masterData/utils'

export interface FetchedTwoEntities<T extends unknown = unknown> {
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  isDeleted: boolean
  data: EntityAllFields[]
  twoEntityValues: T
  errors: MayBeNull<AxiosError | ForcedApiError>[]
  clientsData?: AutocompleteDefaultOption[]
}

type FetchedTwoEntitiesParams<T extends unknown> = Partial<FetchedTwoEntities<T>> &
  Required<Pick<FetchedTwoEntities<T>, 'twoEntityValues'>>

export interface MergeCardProps<T extends any = any>
  extends ComponentProps<typeof WppCard>,
    FetchedTwoEntitiesParams<T> {}

export interface Entry<T> {
  data: T
  entryName: string
  isSameValue?: boolean
}

export const ENTRY_NAME = ['entry1', 'entry2'] as const
export interface MergeableEntity<T, K extends keyof T = keyof T> {
  id: K
  index?: number
  entry1: Entry<UnwrapArray<T[K]>>
  entry2: Entry<UnwrapArray<T[K]>>
}

export type CommonTableRowField = MergeableEntity<EntityFormAllFields>

export interface CommonMergeValues<T extends EntityFormAllFields = EntityFormAllFields, K extends keyof T = keyof T> {
  fields: {
    [key in K]: T[key][]
  }
  tableRowDataFields: Array<MergeableEntity<T>>
}
