import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'
import { MayBeNull } from '@wpp-open/core'

import { MergeableEntity } from 'types/masterData/mergeEntities'
import { EntityAllFields, EntityFormAllFields } from 'types/masterData/utils'

interface Props<T extends EntityAllFields> {
  entries: T[]
  extractFn: (arg: T) => MayBeNull<AutocompleteDefaultOption>
}

export const prepareClientId = <T extends EntityAllFields>({ entries: [entry1, entry2], extractFn }: Props<T>) => {
  const clientId1 = extractFn(entry1)
  const clientId2 = extractFn(entry2)
  const isSameValue = clientId1?.id === clientId2?.id

  if (!clientId1 && !clientId2) return []

  return [
    {
      id: 'clientId',
      index: 0,
      entry1: {
        data: clientId1!,
        isSameValue,
        entryName: entry1?.name! || entry1?.displayName!,
      },
      entry2: {
        data: clientId2!,
        isSameValue,
        entryName: entry2?.name! || entry2?.displayName!,
      },
    } as const satisfies MergeableEntity<EntityFormAllFields>,
  ]
}
