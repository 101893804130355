import { AxiosError } from 'axios'
import { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'

import { useImportEntityApi } from 'api/masterData/mutations/useImportEntityApi'
import { tableActions } from 'components/common/table'
import { ImportEntitiesBody } from 'components/importEntitiesModal/components/ImportEntitiesBody'
import { ImportEntitiesErrorBody } from 'components/importEntitiesModal/components/ImportEntitiesErrorBody'
import { ImportEntitiesSuccessBody } from 'components/importEntitiesModal/components/ImportEntitiesSuccessBody'
import {
  defaultValues,
  handleGroupErrorLines,
  ImportEntityForm,
  ImportEntityResponse,
  ImportError,
  useImportValidationScheme,
} from 'components/importEntitiesModal/utils'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useForm } from 'hooks/form/useForm'
import { useStableCallback } from 'hooks/useStableCallback'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { RouteModules } from 'types/masterData/utils'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'
import { is400Error } from 'utils/error'
import { routesManager } from 'utils/routesManager'

interface Props extends NiceModalWrappedProps {
  moduleName: keyof (typeof routesManager)['masterData']
}

const ImportEntitiesModal = ({ id, isOpen, onClose, onCloseComplete, moduleName }: Props) => {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<ImportError[][]>([])
  const { enqueueToast } = useToast()

  const form = useForm({
    defaultValues: defaultValues,
    validationSchema: useImportValidationScheme(),
  })

  const { mutateAsync: importEntity, error: importError } = useImportEntityApi({
    onSuccess: async ({ data }) => {
      const imported = data.importedLines
      if (imported === 0) return
      const uppercaseModuleName = moduleName.toUpperCase() as Uppercase<RouteModules>
      const queryKey = `MASTER_DATA_${uppercaseModuleName}` as const
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys[queryKey]] })
      tableActions.reload(TableKey[queryKey])
    },
  })

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitted },
  } = form

  const [{ value: importedSuccessful }, onSubmit] = useAsyncFn(
    async (values: ImportEntityForm) => {
      try {
        const { data: response } = await importEntity({
          file: values.file?.[0],
          module: moduleName,
        })

        return response.importedLines
      } catch (err) {
        setValue('file', [])

        if (is400Error(err)) {
          const response = (err as AxiosError<ImportEntityResponse>)?.response?.data
          setErrors(handleGroupErrorLines(response?.errors))
          return
        }
        enqueueToast({
          message: t('common.errors.general'),
          type: 'error',
        })
      }
    },
    [importEntity, enqueueToast, setErrors, setValue],
  )

  const resetModal = useStableCallback(() => {
    reset()
    setErrors([])
  })

  const isSuccessful = isSubmitted && errors.length === 0 && !importError
  const isError = isSubmitted && errors.length > 0 && is400Error(importError)
  const isDefault = !isError && !isSuccessful

  return (
    <FormProvider {...form}>
      <Modal
        formConfig={{ onSubmit: handleSubmit(onSubmit) }}
        open={isOpen}
        onWppModalClose={onClose}
        onWppModalCloseComplete={onCloseComplete}
        disableOutsideClick
        data-testid={id}
      >
        {isDefault && <ImportEntitiesBody form={form} onClose={onClose} moduleName={moduleName} />}
        {isSuccessful && <ImportEntitiesSuccessBody importedSuccessful={importedSuccessful} onClose={onClose} />}
        {isError && <ImportEntitiesErrorBody errors={errors} handleReset={resetModal} onClose={onClose} />}
      </Modal>
    </FormProvider>
  )
}

export const { showModal: showImportEntitiesModal } = createNiceModal(ImportEntitiesModal, 'import-entities-modal')
