import { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

import { RouteModules } from 'types/masterData/utils'

export const initialDataFactory = <T>(data: T): AxiosResponse<T> => {
  return {
    data,
    status: 200,
    statusText: 'OK',
    headers: {},
    config: {} as InternalAxiosRequestConfig,
  }
}

export const getApiModulePath = (module: RouteModules) => {
  if (module === 'markets') return `v2/${module}`
  return module
}
